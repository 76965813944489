import React from "react";
import "@mdxeditor/editor/style.css";
import "./MarkdownEditorV2.css";
import { debounce } from "lodash";
import { MDXEditor } from "@mdxeditor/editor/MDXEditor";
import { useRef, useState } from "react";

import { toolbarPlugin } from "@mdxeditor/editor/plugins/toolbar";
import { listsPlugin } from "@mdxeditor/editor/plugins/lists";
import { quotePlugin } from "@mdxeditor/editor/plugins/quote";
import { linkPlugin } from "@mdxeditor/editor/plugins/link";
import { linkDialogPlugin } from "@mdxeditor/editor/plugins/link-dialog";
// import { markdownShortcutPlugin } from "@mdxeditor/editor/plugins/markdown-shortcut";
import { UndoRedo } from "@mdxeditor/editor/plugins/toolbar/components/UndoRedo";
import { BoldItalicUnderlineToggles } from "@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles";
import { CreateLink } from "@mdxeditor/editor/plugins/toolbar/components/CreateLink";
// import { CodeToggle } from "@mdxeditor/editor/plugins/toolbar/components/CodeToggle";
import { BlockTypeSelect } from "@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect";
import { ListsToggle } from "@mdxeditor/editor/plugins/toolbar/components/ListsToggle";
import { FormControl, FormGroup, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";

const MarkdownEditorV2 = ({
  value,
  placeHolder,
  onChange,
  error,
  helperText,
  maxLength,
}) => {
  const [charCount, setCharCount] = useState(value?.length || 0);
  const ref = useRef(null);

  const constrainText = (value) => {
    // The regular expression /<!--[\s\S]*?-->/g matches all HTML comment blocks (<!-- -->) in the input string
    // Those chars were causing the text not to be displayed in the field
    // Note: you won't be able to save and then see <!-- --> (those will be stripped away)
    const newText = value?.replace(/<!--[\s\S]*?-->/g, "").trim();
    return newText?.slice(0, maxLength) ?? "";
  };

  const onChangeEditorSide = (newValue) => {
    let cutText = newValue;
    if (maxLength && newValue.length) {
      cutText = newValue.slice(0, maxLength);
      console.log("newValue on change ", cutText);
      ref.current?.setMarkdown(cutText);
    }
    setCharCount(cutText?.length || 0);
    onChange(cutText);
  };
  const debouncedOnChange = debounce(onChangeEditorSide, 500);
  return (
    <FormControl fullWidth component="fieldset" error={error}>
      <Typography color={error ? "error" : "textPrimary"} variant="subtitle2">
        {placeHolder}
      </Typography>
      <FormGroup>
        <Box minHeight={93}>
          <MDXEditor
            ref={ref}
            contentEditableClassName={"custom-mdx-editor"}
            markdown={constrainText(value)}
            plugins={[
              listsPlugin(),
              linkPlugin(),
              quotePlugin(),
              linkDialogPlugin(),
              // markdownShortcutPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <BoldItalicUnderlineToggles />
                    <UndoRedo />
                    <CreateLink />
                    <ListsToggle />
                    {/* <CodeToggle /> */}
                    <BlockTypeSelect />
                  </>
                ),
              }),
            ]}
            onChange={debouncedOnChange}
          />
          {charCount > 0 && (
            <Typography
              style={{
                background: "white",
                borderRadius: 4,
                bottom: 8,
                color:
                  charCount === maxLength
                    ? `rgba(245, 39, 39, 0.8)`
                    : `rgba(0, 0, 0, 0.6)`,
                padding: "2px 4px",
                position: "absolute",
                right: 8,
              }}
              variant="caption"
            >
              {maxLength ? `${charCount}/${maxLength}` : null}
            </Typography>
          )}
        </Box>
      </FormGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MarkdownEditorV2;
