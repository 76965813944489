import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import noop from "lodash/noop";
import pick from "lodash/pick";
import keyBy from "lodash/keyBy";
import compose from "lodash/fp/compose";
import fpOrderBy from "lodash/fp/orderBy";
import fpMap from "lodash/fp/map";
import fpFilter from "lodash/fp/filter";
import differenceWith from "lodash/differenceWith";
import snakeCase from "lodash/snakeCase";
import * as XLSX from "xlsx";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import fpGroupBy from "lodash/fp/groupBy";
import { getCookie } from "../lib/cookieUtils";

import useLoading from "../useLoading";
import {
  copyJourney as copyJourneyApi,
  createCompany as createCompanyApi,
  createJourney as createJourneyApi,
  createJourneyMaterial as createJourneyMaterialApi,
  createJourneySemantic as createJourneySemanticApi,
  createJourneyTrigger as createJourneyTriggerApi,
  deleteJourneyMaterial as deleteJourneyMaterialApi,
  deleteJourneySemantic as deleteJourneySemanticApi,
  deleteJourneyStep as deleteJourneyStepApi,
  deleteJourneySemanticsByDay as deleteJourneySemanticsByDayApi,
  deleteJourneySemanticsByFlow as deleteJourneySemanticsByFlowApi,
  deleteJourneyTrigger as deleteJourneyTriggerApi,
  // getTriggersAppLink,
  listCompanies as listCompaniesApi,
  // listCompanyUsers,
  listGeneralJourneys,
  listJourneyMaterials,
  listJourneySemantics,
  listAllSemanticsForUser,
  listTemplateFamilies,
  listTeamsTemplateFamilies,
  listSemanticTemplates,
  listTeamsSemanticTemplates,
  listSemanticPreviewTemplates,
  listJourneyTriggers,
  listUIConfigs,
  updateJourney as updateJourneyApi,
  updateJourneyMaterial as updateJourneyMaterialApi,
  updateJourneySemantic as updateJourneySemanticApi,
  callAiService as callAiServiceApi,
  updateJourneyTrigger as updateJourneyTriggerApi,
  pbiGetConfigCall as pbiGetConfigCallApi,
  authSignOut as authSignOutApi,
  authRefresh as authRefreshApi,
  listLinkedJourneys,
  deleteJourney as deleteJourneyApi,
  linkJourneys as linkJourneysApi,
  unlinkJourneys as unlinkJourneysApi,
  listJourneyGroups as listJourneyGroupsApi,
  createJourneyGroup as createJourneyGroupApi,
  copySemanticFlow as copySemanticFlowApi,
  updateJourneyGroup as updateJourneyGroupApi,
  deleteJourneyGroup as deleteJourneyGroupApi,
  listSpecialLinks as listSpecialLinksApi,
  createSpecialLink as createSpecialLinkApi,
  updateSpecialLink as updateSpecialLinkApi,
  deleteSpecialLink as deleteSpecialLinkApi,
  listTipGroups as listTipGroupsApi,
  createTipGroup as createTipGroupApi,
  updateTipGroup as updateTipGroupApi,
  deleteTipGroup as deleteTipGroupApi,
  listJourneyTriggerConfigs as listJourneyTriggerConfigsApi,
  updateJourneyTriggerConfig as updateJourneyTriggerConfigApi,
  listJourneySemanticsWithEmptyCTALink as listJourneySemanticsWithEmptyCTALinkApi,
  listFlowRelations as listFlowRelationsApi,
  createFlowRelation as createFlowRelationApi,
  deleteFlowRelation as deleteFlowRelationApi,
  listScheduledSlots as listScheduledSlotsApi,
  createScheduledSlot as createScheduledSlotApi,
  updateScheduledSlot as updateScheduledSlotApi,
  deleteScheduledSlot as deleteScheduledSlotApi,
  listConfigurations as listConfigurationsApi,
  getConfiguration as getConfigurationApi,
  createConfiguration as createConfigurationApi,
  updateConfiguration as updateConfigurationApi,
  deleteConfiguration as deleteConfigurationApi,
  listPrompts as listPromptsApi,
  createPrompt as createPromptApi,
  updatePrompt as updatePromptApi,
  deletePrompt as deletePromptApi,
  listTools as listToolsApi,
  createTool as createToolApi,
  updateTool as updateToolApi,
  deleteTool as deleteToolApi,
  listTags as listTagsApi,
  createTag as createTagApi,
  updateTag as updateTagApi,
  deleteTag as deleteTagApi,
  listUsers as listUsersApi,
  getUser as getUserApi,
  createUser as createUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
  getCompany as getCompanyApi,
  updateCompany as updateCompanyApi,
  deleteCompany as deleteCompanyApi,
  listUserStatePaths as listUserStatePathsApi,
  createUserStatePath as createUserStatePathApi,
  updateUserStatePath as updateUserStatePathApi,
  deleteUserStatePath as deleteUserStatePathApi,
  listSurveys as listSurveysApi,
  listAllSurveysForUser as listAllSurveysForUserApi,
  getSurvey as getSurveyApi,
  createSurvey as createSurveyApi,
  updateSurvey as updateSurveyApi,
  deleteSurvey as deleteSurveyApi,
  getTypeform as getTypeformApi,
  getTypeforms as getTypeformsApi,
  listIntegrations as listIntegrationsApi,
  exportImage as exportImageApi,
  createIntegration as createIntegrationApi,
  updateIntegration as updateIntegrationApi,
  deleteIntegration as deleteIntegrationApi,
  listTriggers as listAllTriggers,
  listWelfareSelector as listWelfareSelectorApi,
  getAssociatedWelfareEntity as getAssociatedWelfareEntityApi,
  getBenefit as getBenefitApi,
  listAllBenefits as listAllBenefitsApi,
  // listEntityBenefits as listEntityBenefitsApi,
  listJourneyBenefits as listJourneyBenefitsApi,
  listJourneyNews as listJourneyNewsApi,
  listWelfareCategories as listWelfareCategoriesApi,
  listWelfareSubcategories as listWelfareSubcategoriesApi,
  createWelfareBenefit as createWelfareBenefitApi,
  updateWelfareBenefit as updateWelfareBenefitApi,
  listAngeliniUsers as listAngeliniUsersApi,
  getNews as getNewsApi,
  listAllNews as listAllNewsApi,
  updateWelfareNews as updateWelfareNewsApi,
  createWelfareNews as createWelfareNewsApi,
  listWelfareBookings as listWelfareBookingsApi,
  createWelfareBooking as createWelfareBookingApi,
  updateWelfareBooking as updateWelfareBookingApi,
  listBookingEventsConditions as listBookingEventsConditionsApi,
} from "./index";
import getData from "../lib/getData";
// import { initializeChat } from "../lib/chat";

/* eslint sort-keys: 0 */
const AppContext = createContext({
  authRefresh: noop,
  authSignOut: noop,

  journeys: [],
  isJourneysLoading: false,
  fetchJourneys: noop,

  materials: [],
  isMaterialsLoading: false,
  fetchMaterials: noop,

  uiConfigs: {},
  isUIConfigsLoading: false,
  fetchUIConfigs: noop,

  semantics: [],
  templateFamilies: [],
  teamsTemplateFamilies: [],
  semanticTemplates: [],
  teamsSemanticTemplates: [],
  semanticPreviewTemplates: [],
  isSemanticsLoading: false,
  isAllSemanticsForUserLoading: false,
  isTemplateFamiliesLoading: false,
  isTeamsTemplateFamiliesLoading: false,
  isSemanticTemplatesLoading: false,
  isTeamsSemanticTemplatesLoading: false,
  fetchSemantics: noop,
  fetchAllSemanticsForUser: noop,
  fetchTemplateFamilies: noop,
  fetchTeamsTemplateFamilies: noop,
  fetchSemanticTemplates: noop,
  fetchTeamsSemanticTemplates: noop,

  fetchSemanticPreviewTemplates: noop,
  exportSemantics: noop,

  deleteJourney: noop,
  updateJourney: noop,
  updateJourneySemantic: noop,
  deleteJourneySemanticsByDay: noop,
  deleteJourneySemanticsByFlow: noop,
  deleteJourneySemantic: noop,
  deleteJourneyStep: noop,
  updateJourneyMaterial: noop,

  triggers: [],
  isTriggersLoading: false,
  fetchTriggers: noop,
  updateTrigger: noop,
  deleteTrigger: noop,

  isAllTriggersLoading: false,
  fetchAllTriggers: noop,

  companyUsers: [],
  // isCompanyUsersLoading: false,
  // fetchCompanyUsers: noop,
  createCompany: noop,

  companies: [],
  isCompaniesLoading: false,
  fetchCompanies: noop,

  isCopyJourneyLoading: false,
  copyJourney: noop,
  copySemanticFlow: noop,

  triggersAppLink: null,
  // isTriggersAppLinkLoading: false,
  // fetchTriggersAppLink: noop,

  powerbiEmbedConfig: {},

  isLinkedJourneysLoading: false,
  linkedJourneys: [],
  fetchLinkedJourneys: noop,
  linkJourneys: noop,
  unlinkJourneys: noop,

  isGroupsLoading: false,
  groups: {},
  fetchGroups: noop,
  updateJourneyGroup: noop,
  updateMultipleJourneyGroups: noop,
  deleteJourneyGroup: noop,

  isSpecialLinksLoading: false,
  specialLinks: [],
  updateSpecialLink: noop,
  deleteSpecialLink: noop,
  fetchSpecialLinks: noop,

  isTipGroupsLoading: false,
  tipGroups: [],
  fetchTipGroups: noop,
  updateTipGroup: noop,
  deleteTipGroup: noop,

  fetchTriggerConfigs: noop,
  isTriggerConfigsLoading: false,
  triggerConfigs: [],
  updateTriggerConfig: noop,

  isShowHidden: false,
  setIsShowHidden: noop,

  isJourneySemanticsWithEmptyCTALinkLoading: false,
  fetchJourneySemanticsWithEmptyCTALink: noop,
  journeySemanticsWithEmptyCTALink: [],

  flowRelations: [],
  isFlowRelationsLoading: false,
  fetchFlowRelations: noop,
  createFlowRelation: noop,
  deleteFlowRelation: noop,

  scheduledSlots: [],
  fetchScheduledSlots: noop,
  isScheduledSlotsLoading: false,
  updateScheduledSlot: noop,
  deleteScheduledSlot: noop,

  configurations: [],
  users: [],
  configuration: null,
  // Important: this is the user logged in the content portal (from Cognito); other user related api and variables are not related to this user (ex. isUserLoading)
  user: null,
  cpuser: null, // Important: cpuser is a content portal user that is being created/edited etc. by the user that is logged in the content portal
  fetchConfigurations: noop,

  fetchUsers: noop,
  fetchConfiguration: noop,
  fetchUser: noop,
  isConfigurationsLoading: false,
  isConfigurationLoading: false,

  isPromptsLoading: false,
  isPromptLoading: false,
  prompts: [],
  seeds: [],
  aiSkillingPrompts: [],
  fetchPrompts: noop,
  createPrompt: noop,
  updatePrompt: noop,
  deletePrompt: noop,

  isToolsLoading: false,
  tools: [],
  fetchTools: noop,
  createTool: noop,
  updateTool: noop,
  deleteTool: noop,

  isTagsLoading: false,
  tags: [],
  fetchTags: noop,
  createTag: noop,
  updateTag: noop,
  deleteTag: noop,

  isUsersLoading: false,
  isUserLoading: false,
  isCompanyLoading: false,
  updateConfiguration: noop,
  deleteConfiguration: noop,
  updateUser: noop,
  deleteUser: noop,

  callAiServiceApi: noop,
  isAiServiceLoading: false,

  isUserStatePathsLoading: false,
  fetchUserStatePaths: noop,
  updateUserStatePath: noop,
  deleteUserStatePath: noop,
  userStatePaths: [],

  surveys: [],
  survey: null,
  isSurveysLoading: false,
  isSurveyLoading: false,
  fetchSurveys: noop,
  fetchAllSurveysForUser: noop,
  fetchSurvey: noop,
  createSurvey: noop,
  updateSurvey: noop,
  deleteSurvey: noop,

  integrations: [],
  isIntegrationsLoading: false,
  updateIntegration: noop,
  deleteIntegration: noop,
  fetchIntegrations: noop,

  fetchTypeforms: noop,
  isTypeformsLoading: false,
  typeforms: [],

  welfareSelectors: [{}],
  isWelfareSelectorsLoading: false,
  fetchWelfareSelector: noop,

  associatedWelfareEntity: null,
  isAssociatedWelfareEntityLoading: false,
  fetchAssociatedWelfareEntity: noop,

  benefit: {},
  isBenefitLoading: false,
  fetchBenefit: noop,

  benefits: [],
  isAllBenefitsLoading: false,
  fetchAllBenefits: noop,

  entityBenefits: [],
  isListEntityBenefitsLoading: false,
  fetchEntityBenefits: noop,

  journeyBenefits: [], // remember to refresh it anytime you change journey
  isListJourneyBenefitsLoading: false,
  fetchJourneyBenefits: noop,

  journeyNews: [],
  isListJourneyNewsLoading: false,
  fetchJourneyNews: noop,

  welfareCategories: [],
  isWelfareCategoriesLoading: false,
  fetchWelfareCategories: noop,

  welfareSubcategories: [],
  isWelfareSubcategoriesLoading: false,
  fetchWelfareSubcategories: noop,

  welfareBookings: [],
  isWelfareBookingsLoading: false,
  fetchWelfareBookings: noop,
  updateWelfareBooking: noop,
  deleteWelfareBooking: noop,

  updateWelfareBenefit: noop,
  deleteWelfareBenefit: noop,

  isAngeliniUsersLoading: false,
  fetchAngeliniUsers: noop,

  news: [],
  isAllNewsLoading: false,
  fetchAllNews: noop,

  isNewsLoading: false,
  fetchNews: noop,

  deleteWelfareNews: noop,
  bookingEventsConditions: [],
});

const getCTAType = ({
  type,
  materialsById,
  semanticsById,
  ctaTarget,
  ctaLinkId,
}) => {
  if (type === "Semantic") {
    return `Next Tip - ${semanticsById[ctaTarget]?.title || ""}`;
  }

  if (type === "Dashboard Material") {
    return `Dashboard Material  - ${materialsById[ctaLinkId]?.name || ""}`;
  }

  return type;
};

export const AppContextProvider = ({ children }) => {
  const [companies, setCompanies] = useState([]);
  const [journeys, setJourneys] = useState([]);
  const [associatedWelfareEntity, setAssociatedWelfareEntity] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [semantics, setSemantics] = useState([]);
  const [templateFamilies, setTemplateFamilies] = useState([]);
  const [teamsTemplateFamilies, setTeamsTemplateFamilies] = useState([]);
  const [semanticTemplates, setSemanticTemplates] = useState([]);
  const [teamsSemanticTemplates, setTeamsSemanticTemplates] = useState([]);
  const [semanticPreviewTemplates, setSemanticPreviewTemplates] = useState([]);
  const [uiConfigs, setUIConfigs] = useState({});
  const [triggers, setTriggers] = useState([]);
  const [otherJourneyTriggers, setOtherJourneyTriggers] = useState([]);
  // const [companyUsers, setCompanyUsers] = useState([]);
  // const [triggersAppLink, setTriggersAppLink] = useState(null);
  const [powerbiEmbedConfig, setPowerbiEmbedConfig] = useState({});
  const [linkedJourneys, setLinkedJourneys] = useState([]);
  const [_groups, setGroups] = useState([]);
  const [specialLinks, setSpecialLinks] = useState([]);
  const [tipGroups, setTipGroups] = useState([]);
  const [isShowHidden, setIsShowHidden] = useState(false);
  const [triggerConfigs, setTriggerConfigs] = useState([]);
  const [
    journeySemanticsWithEmptyCTALink,
    setJourneySemanticsWithEmptyCTALink,
  ] = useState([]);
  const [flowRelations, setFlowRelations] = useState([]);
  const [scheduledSlots, setScheduledSlots] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [seeds, setSeeds] = useState([]);
  const [aiSkillingPrompts, setAiSkillingPrompts] = useState([]);
  const [prompt, setPrompt] = useState([]);
  const [tools, setTools] = useState([]);
  const [tool, setTool] = useState([]);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState([]);
  const [users, setUsers] = useState([]);
  const [configuration, setConfiguration] = useState(null);
  const [user, setUser] = useState(null); // this is currently logged in user
  const [cpuser, setCPuser] = useState(null);
  const [company, setCompany] = useState(null);
  const [userStatePaths, setUserStatePaths] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [survey, setSurvey] = useState(null);
  const [typeform, setTypeform] = useState(null);
  const [typeforms, setTypeforms] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [welfareSelectors, setWelfareSelectors] = useState({
    entities: [],
    contractType: [],
    employeeRole: [],
    officeType: [],
    employeeType: [],
    officeLocation: [],
  });
  const [benefits, setBenefits] = useState([]);
  const [journeyBenefits, setJourneyBenefits] = useState([]);
  const [journeyNews, setJourneyNews] = useState([]);

  const [benefit, setBenefit] = useState(null);
  const [welfareCategories, setWelfareCategories] = useState([]);
  const [welfareSubcategories, setWelfareSubcategories] = useState([]);
  const [welfareBookings, setWelfareBookings] = useState([]);
  const [bookingEventsConditions, setBookingEventsConditions] = useState([]);

  const [news, setNews] = useState([]);

  const groups = useMemo(
    () =>
      compose(fpGroupBy("stimulusId"), fpOrderBy(["order"], ["asc"]))(_groups),
    [_groups]
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // initializeChat(user);
  }, [user]);

  const { isLoading: isJourneysLoading, handler: fetchGeneralJourneysHandler } =
    useLoading(listGeneralJourneys);

  const { isLoading: isSemanticsLoading, handler: fetchSemanticsHandler } =
    useLoading(listJourneySemantics);

  const {
    isLoading: isAllSemanticsForUserLoading,
    handler: fetchAllSemanticsForUserHandler,
  } = useLoading(listAllSemanticsForUser);

  const {
    isLoading: isTemplateFamiliesLoading,
    handler: fetchTemplateFamiliesHandler,
  } = useLoading(listTemplateFamilies);

  const {
    isLoading: isTeamsTemplateFamiliesLoading,
    handler: fetchTeamsTemplateFamiliesHandler,
  } = useLoading(listTeamsTemplateFamilies);

  const {
    isLoading: isSemanticTemplatesLoading,
    handler: fetchSemanticTemplatesHandler,
  } = useLoading(listSemanticTemplates);

  const {
    isLoading: isTeamsSemanticTemplatesLoading,
    handler: fetchTeamsSemanticTemplatesHandler,
  } = useLoading(listTeamsSemanticTemplates);

  const {
    isLoading: isSemanticPreviewTemplatesLoading,
    handler: fetchSemanticPreviewTemplatesHandler,
  } = useLoading(listSemanticPreviewTemplates);

  const { isLoading: isMaterialsLoading, handler: fetchMaterialsHandler } =
    useLoading(listJourneyMaterials);

  const { isLoading: isUIConfigsLoading, handler: fetchUIConfigsHandler } =
    useLoading(listUIConfigs);

  const { isLoading: isTriggersLoading, handler: fetchTriggersHandler } =
    useLoading(listJourneyTriggers);

  const { isLoading: isAllTriggersLoading, handler: fetchAllTriggersHandler } =
    useLoading(listAllTriggers);

  // const {
  //   isLoading: isCompanyUsersLoading,
  //   handler: fetchCompanyUsersHandler,
  // } = useLoading(listCompanyUsers);

  const { isLoading: isCompaniesLoading, handler: fetchCompaniesHandler } =
    useLoading(listCompaniesApi);

  // const {
  //   isLoading: isTriggersAppLinkLoading,
  //   handler: fetchTriggersAppLinkHandler,
  // } = useLoading(getTriggersAppLink);

  const {
    isLoading: isLinkedJourneysLoading,
    handler: fetchLinkedJourneysHandler,
  } = useLoading(listLinkedJourneys);

  const { isLoading: isGroupsLoading, handler: fetchGroupsHandler } =
    useLoading(listJourneyGroupsApi);

  const {
    isLoading: isSpecialLinksLoading,
    handler: fetchSpecialLinksHandler,
  } = useLoading(listSpecialLinksApi);

  const { isLoading: isTipGroupsLoading, handler: fetchTipGroupsHandler } =
    useLoading(listTipGroupsApi);

  const {
    isLoading: isTriggerConfigsLoading,
    handler: fetchJourneyTriggerConfigsHandler,
  } = useLoading(listJourneyTriggerConfigsApi);

  const {
    isLoading: isJourneySemanticsWithEmptyCTALinkLoading,
    handler: fetchJourneySemanticsWithEmptyCTALinkHandler,
  } = useLoading(listJourneySemanticsWithEmptyCTALinkApi);

  const {
    isLoading: isFlowRelationsLoading,
    handler: fetchFlowRelationsHandler,
  } = useLoading(listFlowRelationsApi);

  const {
    isLoading: isScheduledSlotsLoading,
    handler: fetchScheduledSlotsHandler,
  } = useLoading(listScheduledSlotsApi);

  const {
    isLoading: isConfigurationsLoading,
    handler: fetchConfigurationsHandler,
  } = useLoading(listConfigurationsApi);

  const { isLoading: isPromptsLoading, handler: fetchPromptsHandler } =
    useLoading(listPromptsApi);

  const { isLoading: isToolsLoading, handler: fetchToolsHandler } =
    useLoading(listToolsApi);

  const { isLoading: isTagsLoading, handler: fetchTagsHandler } =
    useLoading(listTagsApi);

  const {
    isLoading: isConfigurationLoading,
    handler: fetchConfigurationHandler,
  } = useLoading(getConfigurationApi);

  const { isLoading: isAiServiceLoading, handler: postAiServiceHandler } =
    useLoading(callAiServiceApi);

  const { isLoading: isUsersLoading, handler: fetchUsersHandler } =
    useLoading(listUsersApi);

  const { isLoading: isUserLoading, handler: fetchUserHandler } =
    useLoading(getUserApi);

  const { isLoading: isCompanyLoading, handler: fetchCompanyHandler } =
    useLoading(getCompanyApi);

  const {
    isLoading: isUserStatePathsLoading,
    handler: fetchUserStatePathsHandler,
  } = useLoading(listUserStatePathsApi);

  const { isLoading: isSurveyLoading, handler: fetchSurveyHandler } =
    useLoading(getSurveyApi);

  const { isLoading: isSurveysLoading, handler: fetchSurveysHandler } =
    useLoading(listSurveysApi);

  const {
    isLoading: isAllSurveysForUserLoading,
    handler: fetchAllSurveysForUserHandler,
  } = useLoading(listAllSurveysForUserApi);

  const { isLoading: isTypeformLoading, handler: fetchTypeformHandler } =
    useLoading(getTypeformApi);

  const { isLoading: isTypeformsLoading, handler: fetchTypeformsHandler } =
    useLoading(getTypeformsApi);

  const {
    isLoading: isIntegrationsLoading,
    handler: fetchIntegrationsHandler,
  } = useLoading(listIntegrationsApi);

  const {
    isLoading: isWelfareSelectorsLoading,
    handler: fetchWelfareSelectorsHandler,
  } = useLoading(listWelfareSelectorApi);

  const {
    isLoading: isAssociatedWelfareEntityLoading,
    handler: fetchAssociatedWelfareEntityHandler,
  } = useLoading(getAssociatedWelfareEntityApi);

  const { isLoading: isBenefitLoading, handler: fetchBenefitHandler } =
    useLoading(getBenefitApi);

  const { isLoading: isAllBenefitsLoading, handler: fetchAllBenefitsHandler } =
    useLoading(listAllBenefitsApi);

  // const {
  //   isLoading: isListEntityBenefitsLoading,
  //   handler: fetchEntityBenefitsHandler,
  // } = useLoading(listEntityBenefitsApi);

  const {
    isLoading: isListJourneyBenefitsLoading,
    handler: fetchJourneyBenefitsHandler,
  } = useLoading(listJourneyBenefitsApi);

  const {
    isLoading: isListJourneyNewsLoading,
    handler: fetchJourneyNewsHandler,
  } = useLoading(listJourneyNewsApi);

  const {
    isLoading: isWelfareCategoriesLoading,
    handler: fetchWelfareCategoriesHandler,
  } = useLoading(listWelfareCategoriesApi);

  const {
    isLoading: isWelfareSubcategoriesLoading,
    handler: fetchWelfareSubcategoriesHandler,
  } = useLoading(listWelfareSubcategoriesApi);

  const {
    isLoading: isWelfareBookingsLoading,
    handler: fetchWelfareBookingsHandler,
  } = useLoading(listWelfareBookingsApi);

  const {
    isLoading: isAngeliniUsersLoading,
    handler: fetchAngeliniUsersHandler,
  } = useLoading(listAngeliniUsersApi);

  const { isLoading: isNewsLoading, handler: fetchNewsHandler } =
    useLoading(getNewsApi);

  const { isLoading: isAllNewsLoading, handler: fetchAllNewsHandler } =
    useLoading(listAllNewsApi);

  const fetchIntegrations = useCallback(async () => {
    const integrations = await fetchIntegrationsHandler();
    setIntegrations(integrations);
  }, [fetchIntegrationsHandler]);

  const fetchTypeform = useCallback(
    async ({ id, integrationId }) => {
      const typeform = await fetchTypeformHandler({ id, integrationId });
      setTypeform(typeform);
    },
    [fetchTypeformHandler]
  );

  const fetchTypeforms = useCallback(
    async ({ integrationId }) => {
      const typeforms = await fetchTypeformsHandler({
        integrationId,
      });
      setTypeforms(typeforms);
    },
    [fetchTypeformsHandler]
  );

  const fetchSurvey = useCallback(
    async ({ id }) => {
      const survey = await fetchSurveyHandler({ id });
      setSurvey(survey);
    },
    [fetchSurveyHandler]
  );

  const fetchSurveys = useCallback(
    async ({ journeyId }) => {
      const surveys = await fetchSurveysHandler({ journeyId });
      setSurveys(surveys);
    },
    [fetchSurveysHandler]
  );

  const fetchAllSurveysForUser = useCallback(
    async ({ journeyId }) => {
      const surveys = await fetchAllSurveysForUserHandler({ journeyId });
      setSurveys(surveys);
    },
    [fetchAllSurveysForUserHandler]
  );
  const fetchConfiguration = useCallback(
    async ({ cid }) => {
      try {
        const configuration = await fetchConfigurationHandler({ cid });
        setConfiguration(configuration);
      } catch (e) {
        setConfiguration(null);
      }
    },
    [fetchConfigurationHandler]
  );
  const fetchUser = useCallback(
    async ({ id }) => {
      try {
        const cpuser = await fetchUserHandler({ id });
        setCPuser(cpuser);
      } catch (e) {
        setCPuser(null);
      }
    },
    [fetchUserHandler]
  );
  const fetchCompany = useCallback(
    async ({ id }) => {
      try {
        const company = await fetchCompanyHandler({ id });
        setCompany(company);
      } catch (e) {
        setCompany(null);
      }
    },
    [fetchCompanyHandler]
  );

  const fetchConfigurations = useCallback(async () => {
    try {
      const configurations = await fetchConfigurationsHandler({});
      setConfigurations(configurations);
    } catch (e) {
      setConfigurations([]);
    }
  }, [fetchConfigurationsHandler]);

  const fetchPrompts = useCallback(
    async (section = "copilot") => {
      try {
        const data = await fetchPromptsHandler(section);
        if (section === "allitude") {
          setSeeds(data);
        }
        if (section === "aiskilling") {
          setAiSkillingPrompts(data);
        } else {
          setPrompts(data);
        }
      } catch (e) {
        if (section === "allitude") {
          setSeeds([]);
        }
        if (section === "aiskilling") {
          setAiSkillingPrompts([]);
        } else {
          setPrompts([]);
        }
      }
    },
    [fetchPromptsHandler]
  );

  const fetchTools = useCallback(
    async (section = "copilot") => {
      try {
        const tools = await fetchToolsHandler(section);
        setTools(tools);
      } catch (e) {
        setTools([]);
      }
    },
    [fetchToolsHandler]
  );

  const fetchTags = useCallback(
    async (section = "copilot") => {
      try {
        const tags = await fetchTagsHandler(section);
        setTags(tags);
      } catch (e) {
        setTags([]);
      }
    },
    [fetchTagsHandler]
  );

  const fetchUsers = useCallback(async () => {
    try {
      const users = await fetchUsersHandler({});
      setUsers(users);
    } catch (e) {
      setUsers([]);
    }
  }, [fetchUsersHandler]);

  const fetchUserStatePaths = useCallback(
    async (searchParams = {}) => {
      try {
        const userStatePaths = await fetchUserStatePathsHandler(searchParams);
        setUserStatePaths(
          userStatePaths.sort(function (a, b) {
            const x = a.displayName?.toLowerCase();
            const y = b.displayName?.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          })
        );
      } catch (e) {
        setUserStatePaths([]);
      }
    },
    [fetchUserStatePathsHandler]
  );

  const fetchScheduledSlots = useCallback(
    async ({ journeyId }) => {
      try {
        const scheduledSlots = await fetchScheduledSlotsHandler({
          journeyId,
        });
        setScheduledSlots(scheduledSlots);
      } catch (e) {
        setScheduledSlots([]);
      }
    },
    [fetchScheduledSlotsHandler]
  );

  const fetchFlowRelations = useCallback(
    async ({ journeyId, locale }) => {
      try {
        const relations = await fetchFlowRelationsHandler({
          journeyId,
          locale,
        });
        setFlowRelations(relations);
      } catch (e) {
        setFlowRelations([]);
      }
    },
    [fetchFlowRelationsHandler]
  );

  const fetchJourneySemanticsWithEmptyCTALink = useCallback(
    async ({ journeyId }) => {
      try {
        const semantics = await fetchJourneySemanticsWithEmptyCTALinkHandler({
          journeyId,
        });
        setJourneySemanticsWithEmptyCTALink(semantics);
      } catch (e) {
        setJourneySemanticsWithEmptyCTALink([]);
      }
    },
    [fetchJourneySemanticsWithEmptyCTALinkHandler]
  );

  const fetchTriggerConfigs = useCallback(
    async ({ journeyId }) => {
      const configs = await fetchJourneyTriggerConfigsHandler({
        journeyId,
      });
      setTriggerConfigs(configs || []);
    },
    [fetchJourneyTriggerConfigsHandler]
  );

  const fetchSpecialLinks = useCallback(
    async ({ all = false } = {}) => {
      const links = await fetchSpecialLinksHandler({ all });
      setSpecialLinks(links || []);
    },
    [fetchSpecialLinksHandler]
  );

  const fetchTipGroups = useCallback(
    async ({ all = false } = {}) => {
      const tipGroups = await fetchTipGroupsHandler({ all });
      setTipGroups(tipGroups || []);
    },
    [fetchTipGroupsHandler]
  );
  const fetchLinkedJourneys = useCallback(
    async (journeyId) => {
      const journeys = await fetchLinkedJourneysHandler(journeyId);
      setLinkedJourneys(journeys);
    },
    [fetchLinkedJourneysHandler]
  );

  const fetchGroups = useCallback(
    async ({ journeyId, locale }) => {
      const groups = await fetchGroupsHandler({ journeyId, locale });
      setGroups(groups);
    },
    [fetchGroupsHandler]
  );

  const fetchJourneys = useCallback(async () => {
    try {
      const journeys = await fetchGeneralJourneysHandler();

      setJourneys(journeys);
    } catch (e) {
      setJourneys([]);
    }
  }, [fetchGeneralJourneysHandler, user?.groups]);

  const fetchSemantics = useCallback(
    async ({ journeyId, locale }) => {
      try {
        const semantics = await fetchSemanticsHandler({ journeyId, locale });
        setSemantics(semantics);
        return semantics;
      } catch (e) {
        setSemantics([]);
        return [];
      }
    },
    [fetchSemanticsHandler]
  );

  const fetchAllSemanticsForUser = useCallback(
    async ({ journeyId, locale, surveyId }) => {
      try {
        const { tipsAndNudges, scheduledSlots } =
          await fetchAllSemanticsForUserHandler({
            journeyId,
            locale,
            surveyId,
          });
        setSemantics(tipsAndNudges);
        setScheduledSlots(scheduledSlots);
        return { tipsAndNudges, scheduledSlots };
      } catch (e) {
        setSemantics([]);
        setScheduledSlots([]);
        return [];
      }
    },
    [fetchAllSemanticsForUserHandler]
  );

  const fetchTemplateFamilies = useCallback(async () => {
    try {
      const templateFamilies = await fetchTemplateFamiliesHandler();
      setTemplateFamilies(templateFamilies);
      return templateFamilies;
    } catch (e) {
      setTemplateFamilies([]);
      return [];
    }
  }, [fetchTemplateFamiliesHandler]);

  const fetchTeamsTemplateFamilies = useCallback(async () => {
    try {
      const teamsTemplateFamilies = await fetchTeamsTemplateFamiliesHandler();
      setTeamsTemplateFamilies(teamsTemplateFamilies);
      return teamsTemplateFamilies;
    } catch (e) {
      setTeamsTemplateFamilies([]);
      return [];
    }
  }, [fetchTeamsTemplateFamiliesHandler]);

  const fetchSemanticTemplates = useCallback(
    async ({ semanticTemplateId }) => {
      try {
        const semanticTemplates = await fetchSemanticTemplatesHandler({
          semanticTemplateId,
        });
        setSemanticTemplates(semanticTemplates);
        return semanticTemplates;
      } catch (e) {
        setSemanticTemplates([]);
        return [];
      }
    },
    [fetchSemanticTemplatesHandler]
  );

  const fetchTeamsSemanticTemplates = useCallback(
    async ({ teamsSemanticTemplateId }) => {
      try {
        const teamsSemanticTemplates = await fetchTeamsSemanticTemplatesHandler(
          {
            teamsSemanticTemplateId,
          }
        );
        setTeamsSemanticTemplates(teamsSemanticTemplates);
        return teamsSemanticTemplates;
      } catch (e) {
        setTeamsSemanticTemplates([]);
        return [];
      }
    },
    [fetchTeamsSemanticTemplatesHandler]
  );

  const fetchSemanticPreviewTemplates = useCallback(
    async ({ semanticPreviewTemplateId }) => {
      try {
        const semanticPreviewTemplates =
          await fetchSemanticPreviewTemplatesHandler({
            semanticPreviewTemplateId,
          });
        setSemanticPreviewTemplates(semanticPreviewTemplates);
        return semanticPreviewTemplates;
      } catch (e) {
        setSemanticPreviewTemplates([]);
        return [];
      }
    },
    [fetchSemanticPreviewTemplatesHandler]
  );

  const fetchMaterials = useCallback(
    async ({ journeyId, locale }) => {
      try {
        const materials = await fetchMaterialsHandler({ journeyId, locale });
        setMaterials(materials);
      } catch (e) {
        setMaterials([]);
      }
    },
    [fetchMaterialsHandler]
  );

  const fetchUIConfigs = useCallback(async () => {
    try {
      const uiConfigs = await fetchUIConfigsHandler();
      setUIConfigs(uiConfigs);
    } catch (e) {
      setUIConfigs({});
    }
  }, [fetchUIConfigsHandler]);

  const fetchTriggers = useCallback(
    // otherJourneyId is used to set a different variable than triggers (not to confuse whose triggers these are)
    async ({ journeyId, otherJourneyId = null }) => {
      try {
        if (!otherJourneyId) {
          const triggers = await fetchTriggersHandler({ journeyId });
          setTriggers(
            triggers.map(({ createdAt, updatedAt, ...props }) => ({
              ...props,
              createdAt: createdAt && new Date(createdAt * 1000),
              updatedAt: updatedAt && new Date(updatedAt * 1000),
            }))
          );
        }
      } catch (e) {
        setTriggers([]);
      }

      try {
        if (otherJourneyId) {
          const otherJourneyTriggers = await fetchTriggersHandler({
            journeyId: otherJourneyId,
          });
          setOtherJourneyTriggers(
            otherJourneyTriggers.map(({ createdAt, updatedAt, ...props }) => ({
              ...props,
              createdAt: createdAt && new Date(createdAt * 1000),
              updatedAt: updatedAt && new Date(updatedAt * 1000),
            }))
          );
        }
      } catch (e) {
        setTriggers([]);
      }
    },
    [fetchTriggersHandler]
  );

  const fetchAllTriggers = useCallback(async () => {
    try {
      const triggers = await fetchAllTriggersHandler();
      setTriggers(
        triggers.map(({ createdAt, updatedAt, ...props }) => ({
          ...props,
          createdAt: createdAt && new Date(createdAt * 1000),
          updatedAt: updatedAt && new Date(updatedAt * 1000),
        }))
      );
    } catch (e) {
      setTriggers([]);
    }
  }, [fetchAllTriggersHandler]);

  const _update = useCallback(
    (
      array,
      item,
      {
        isMerge = true,
        isDelete = false,
        createAtTheBeginning = false,
        primaryColumn = "id",
      } = {}
    ) => {
      const index = array.findIndex(
        (it) => it[primaryColumn] === item[primaryColumn]
      );
      const newArray = [...array];
      newArray.splice(
        ~index ? index : createAtTheBeginning ? 0 : array.length,
        ~index ? 1 : 0,
        ...(isDelete
          ? []
          : [
              isMerge
                ? {
                    ...newArray[index],
                    ...item,
                  }
                : { ...item },
            ])
      );

      return newArray;
    },
    []
  );

  const deleteSurvey = useCallback(
    async ({ id }) => {
      try {
        await deleteSurveyApi({ id });
        setSurveys(_update(surveys, { id }, { isDelete: true }));
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete survey error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar, surveys]
  );

  const exportImage = useCallback(
    async (exportImageRequest) => {
      try {
        const response = await exportImageApi(exportImageRequest);
        return response;
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Export image error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const updateIntegration = useCallback(
    async ({ isNew, ...integration }) => {
      try {
        const newIntegration = await (isNew
          ? createIntegrationApi(integration)
          : updateIntegrationApi(integration));
        setIntegrations((integrations) =>
          _update(integrations, newIntegration, {
            isMerge: false,
            createAtTheBeginning: true,
          })
        );
        return newIntegration;
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} integration error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [integrations, _update, enqueueSnackbar]
  );

  const deleteIntegration = useCallback(
    async ({ id }) => {
      try {
        await deleteIntegrationApi(id);
        setIntegrations(_update(integrations, { id }, { isDelete: true }));
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Delete integration error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [_update, enqueueSnackbar, integrations]
  );

  const updateSurvey = useCallback(
    async ({ isNew, ...survey }) => {
      const surveysSnapshot = [...surveys];
      const surveySnapshot = { ...survey };
      try {
        setSurvey((oldSurvey) => ({
          ...oldSurvey,
          ...survey,
        }));
        setSurveys(
          _update(surveys, survey, {
            createAtTheBeginning: true,
            primaryColumn: "cid",
          })
        );
        const newSurvey = await (isNew
          ? createSurveyApi(survey)
          : updateSurveyApi(survey));

        setSurveys(
          _update(surveys, survey, {
            createAtTheBeginning: true,
            isMerge: false,
          })
        );
        setSurvey(newSurvey);
        return newSurvey;
      } catch (e) {
        console.error(e.message);
        setSurveys(surveysSnapshot);
        setSurvey(surveySnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} survey error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [_update, enqueueSnackbar, surveys]
  );

  const updateTrigger = useCallback(
    async (journeyId, trigger) => {
      try {
        const updatedTrigger = await (trigger.createdAt
          ? updateJourneyTriggerApi
          : createJourneyTriggerApi)(journeyId, trigger);
        setTriggers((triggers) =>
          _update(
            triggers,
            {
              ...updatedTrigger,
              createdAt:
                updatedTrigger.createdAt &&
                new Date(updatedTrigger.createdAt * 1000),
              updatedAt:
                updatedTrigger.updatedAt &&
                new Date(updatedTrigger.updatedAt * 1000),
            },
            {
              createAtTheBeginning: true,
            }
          )
        );

        return updatedTrigger;
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(
          `${trigger.createdAt ? "Update" : "Create"} trigger error`,
          {
            variant: "error",
          }
        );
      }
    },
    [_update, enqueueSnackbar]
  );

  const deleteTrigger = useCallback(
    async (journeyId, id) => {
      try {
        await deleteJourneyTriggerApi(journeyId, id);
        setTriggers((triggerSelectors) =>
          triggerSelectors.filter((trigger) => id !== trigger.id)
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete trigger error", {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  // const fetchCompanyUsers = useCallback(async () => {
  //   try {
  //     if (!user) {
  //       return;
  //     }
  //     const companyUsers = await fetchCompanyUsersHandler({
  //       cid: user["custom:cid"] || user["name"],
  //     });
  //     setCompanyUsers(companyUsers.filter(({ enabled }) => enabled));
  //   } catch (e) {
  //     setCompanyUsers([]);
  //   }
  // }, [fetchCompanyUsersHandler, user]);

  // const createCompany = useCallback(
  //   async (company) => {
  //     try {
  //       const newCompany = await createCompanyApi(company);
  //       setCompanies((companies) => [newCompany, ...companies]);
  //       return newCompany;
  //     } catch (e) {
  //       enqueueSnackbar("Create company error", { variant: "error" });
  //     }

  //     return null;
  //   },
  //   [enqueueSnackbar]
  // );

  const fetchCompanies = useCallback(async () => {
    try {
      if (!user) {
        return;
      }
      const companies = await fetchCompaniesHandler({
        cid: user["custom:cid"] || user["name"],
      });
      setCompanies(companies);
    } catch (e) {
      console.error("Fetch companies", e.message);
      setCompanies([]);
    }
  }, [fetchCompaniesHandler, user]);

  const updateJourney = useCallback(
    async ({ isNew, stats, ...journey }, { optimisticUpdates = true } = {}) => {
      const journeySnapshot = journeys.find(({ id }) => id === journey.id);
      try {
        if (optimisticUpdates) {
          setJourneys((journeys) =>
            _update(journeys, {
              ...journeySnapshot,
              ...journey,
              stats: journeySnapshot?.stats,
            })
          );
        }
        const newJourney = await (isNew ? createJourneyApi : updateJourneyApi)(
          journey
        );
        setJourneys((journeys) =>
          _update(journeys, {
            ...newJourney,
            stats: journeySnapshot?.stats,
          })
        );
      } catch (e) {
        console.error(e);
        if (optimisticUpdates && journeySnapshot) {
          setJourneys((journeys) => _update(journeys, journeySnapshot));
        }
        enqueueSnackbar(
          `${isNew ? "Create journey error" : "Update journey error"}${
            e.name === "ApiError" ? `: ${e.message}` : ""
          }`,
          { variant: "error" }
        );
      }
    },
    [_update, enqueueSnackbar, journeys]
  );

  const updateJourneySemantic = useCallback(
    async ({ isNew, ...semanticRaw }, { optimisticUpdates = true } = {}) => {
      const semanticsSnapshot = [...semantics];
      try {
        const semantic = {
          ...semanticRaw,
          ...(semanticRaw.ctaType && {
            cta1LinkId: null,
            cta1Target: null,
            cta1Text: "",
            cta1Type: null,
            cta2LinkId: null,
            cta2Target: null,
            cta2Text: "",
            cta2Type: null,
            dropdownData: null,
          }),
          ...(semanticRaw.ctaType === "CTA" &&
            pick(semanticRaw, [
              "cta1Type",
              "cta1Text",
              ...([
                "External Link",
                "Semantic",
                "Welfare",
                "Prompt",
                "Prompt Tool",
                "News",
                "Bloom Seed",
                "AI Skilling Prompt",
              ].includes(semanticRaw.cta1Type)
                ? ["cta1Target"]
                : []),
              ...([
                "Dashboard Material",
                "Welfare",
                "News",
                "Special Link",
                "Dashboard Step",
                "Survey",
                "Prompt",
                "Prompt Tool",
                "Bloom Seed",
                "AI Skilling Prompt",
              ].includes(semanticRaw.cta1Type)
                ? ["cta1LinkId"]
                : []),
            ])),
          ...(semanticRaw.ctaType === "DOUBLE_CTA" &&
            pick(semanticRaw, [
              "cta1Type",
              "cta1Text",
              ...([
                "External Link",
                "Semantic",
                "Welfare",
                "News",
                "Prompt",
                "Prompt Tool",
                "Bloom Seed",
                "AI Skilling Prompt",
              ].includes(semanticRaw.cta1Type)
                ? ["cta1Target"]
                : []),
              ...([
                "Dashboard Material",
                "Welfare",
                "News",
                "Special Link",
                "Dashboard Step",
                "Survey",
                "Prompt",
                "Prompt Tool",
                "Bloom Seed",
                "AI Skilling Prompt",
              ].includes(semanticRaw.cta1Type)
                ? ["cta1LinkId"]
                : []),
              "cta2Type",
              "cta2Text",
              ...([
                "External Link",
                "Semantic",
                "Welfare",
                "News",
                "Prompt",
                "Prompt Tool",
                "Bloom Seed",
                "AI Skilling Prompt",
              ].includes(semanticRaw.cta2Type)
                ? ["cta2Target"]
                : []),
              ...([
                "Dashboard Material",
                "Welfare",
                "News",
                "Special Link",
                "Dashboard Step",
                "Survey",
                "Prompt",
                "Prompt Tool",
                "Bloom Seed",
                "AI Skilling Prompt",
              ].includes(semanticRaw.cta2Type)
                ? ["cta2LinkId"]
                : []),
            ])),
          ...(semanticRaw.ctaType === "DROPDOWN" &&
            pick(semanticRaw, ["cta1Text", "dropdownData"])),
        };

        if (optimisticUpdates) {
          setSemantics((semantics) => _update(semantics, semantic));
        }

        const newSemantic = await (isNew
          ? createJourneySemanticApi(semantic)
          : updateJourneySemanticApi(semantic));
        setSemantics((semantics) =>
          _update(
            semantics.map((semantic) => ({
              ...semantic,
              isFirstInFlow:
                newSemantic.isFirstInFlow &&
                newSemantic.id !== semantic.id &&
                newSemantic.flowId === semantic.flowId
                  ? false
                  : semantic.isFirstInFlow,
              miniTipAutoProgress:
                newSemantic.miniTipAutoProgress &&
                newSemantic.id !== semantic.id &&
                newSemantic.flowId === semantic.flowId
                  ? false
                  : semantic.miniTipAutoProgress,
            })),
            newSemantic,
            { isMerge: false }
          )
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`${isNew ? "Create" : "Update"} semantic error`, {
          variant: "error",
        });
        if (optimisticUpdates) {
          setSemantics(semanticsSnapshot);
        }
      }
    },
    [_update, enqueueSnackbar]
  );

  const deleteJourneySemanticsByDay = useCallback(
    async ({ journeyId, day }) => {
      try {
        const deletedSemantics = await deleteJourneySemanticsByDayApi({
          journeyId,
          day,
        });
        const ids = deletedSemantics.map(({ id }) => id);
        setSemantics((semantics) =>
          semantics.filter((semantic) => !ids.includes(semantic.id))
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete journey semantics by day error", {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const deleteJourneySemanticsByFlow = useCallback(
    async ({ journeyId, flowId }) => {
      try {
        const deletedSemantics = await deleteJourneySemanticsByFlowApi({
          journeyId,
          flowId,
        });
        const ids = deletedSemantics.map(({ id }) => id);
        setSemantics((semantics) =>
          semantics.filter((semantic) => !ids.includes(semantic.id))
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete journey semantics by flow error", {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const deleteJourneyStep = useCallback(
    async ({ journeyId, stepId }) => {
      try {
        const { journey, deletedFlowRelations } = await deleteJourneyStepApi({
          journeyId,
          stepId,
        });

        const journeySnapshot = journeys.find(({ id }) => id === journey.id);
        setJourneys((journeys) =>
          _update(journeys, {
            ...journey,
            stats: journeySnapshot.stats,
          })
        );

        setFlowRelations((flowRelations) =>
          flowRelations.filter(
            (flowRelation) =>
              !deletedFlowRelations.some(
                ({ flowId, relationId }) =>
                  flowId === flowRelation.flowId &&
                  relationId === flowRelation.relationId
              )
          )
        );

        setMaterials((materials) =>
          materials.filter((material) => material.stepId !== stepId)
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete journey step error", {
          variant: "error",
        });
      }
    },
    [_update, enqueueSnackbar, journeys]
  );

  const deleteJourneySemantic = useCallback(
    async ({ journeyId, id }) => {
      try {
        await deleteJourneySemanticApi({
          journeyId,
          id,
        });
        setSemantics((semantics) =>
          _update(semantics, { id }, { isDelete: true })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete journey semantics by day error", {
          variant: "error",
        });
      }
    },
    [_update, enqueueSnackbar]
  );

  const updateJourneyMaterial = useCallback(
    async ({ isNew, ...material }) => {
      const materialsSnapshot = [...materials];
      try {
        setMaterials(_update(materials, material));
        const newMaterial = await (isNew
          ? createJourneyMaterialApi(material)
          : updateJourneyMaterialApi(material));
        setMaterials(_update(materials, newMaterial, { isMerge: false }));
      } catch (e) {
        console.error(e.message);
        setMaterials(materialsSnapshot);
        enqueueSnackbar(`${isNew ? "Create" : "Update"} material error`, {
          variant: "error",
        });
      }
    },
    [_update, enqueueSnackbar, materials]
  );

  const deleteJourneyMaterial = useCallback(
    async ({ id, journeyId }) => {
      try {
        await deleteJourneyMaterialApi({ id, journeyId });

        setMaterials((materials) =>
          _update(materials, { id }, { isDelete: true })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete material error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const { isLoading: isCopyJourneyLoading, handler: copyJourneyHandler } =
    useLoading(copyJourneyApi, { throwError: true });

  const copyJourney = useCallback(
    async ({ journeyId, locale }) => {
      try {
        const newJourney = await copyJourneyHandler({ journeyId, locale });
        setJourneys((journeys) => [newJourney, ...journeys]);
        enqueueSnackbar(
          <div>
            Journey copied 🎉
            <div>
              <Link to={`/journeys/${newJourney.id}/${newJourney.locale}`}>
                Open copied Journey
              </Link>
            </div>
          </div>,
          {
            variant: "success",
          }
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Copy Journey error`, {
          variant: "error",
        });
      }
    },
    [copyJourneyHandler, enqueueSnackbar]
  );

  // const fetchTriggersAppLink = useCallback(async () => {
  //   try {
  //     const link = await fetchTriggersAppLinkHandler();
  //     setTriggersAppLink(link);
  //   } catch (e) {
  //     console.error(e.message);
  //   }
  // }, [fetchTriggersAppLinkHandler]);

  const exportSemantics = useCallback(
    async ({ journeyId, locale }) => {
      let semantics;
      let triggers;
      let materials;
      let flowRelations;
      let scheduledSlots;
      try {
        [semantics, triggers, materials, flowRelations, scheduledSlots] =
          await Promise.all([
            listJourneySemantics({ journeyId, locale }),
            listJourneyTriggers({ journeyId }),
            listJourneyMaterials({ journeyId, locale }),
            listFlowRelationsApi({ journeyId, locale }),
            listScheduledSlotsApi({ journeyId }),
          ]);
        setSemantics(semantics);
        setTriggers(triggers);
        setMaterials(materials);
        setFlowRelations(flowRelations);
        setScheduledSlots(scheduledSlots);
      } catch (e) {
        setSemantics([]);
        setTriggers([]);
        setMaterials([]);
        setFlowRelations([]);
        setScheduledSlots([]);
        throw e;
      }

      const materialsById = keyBy(materials, "id");
      const semanticsById = keyBy(semantics, "id");

      const journey = journeys.find(
        (journey) => journey.id === journeyId && journey.locale === locale
      );

      let {
        primesByDays,
        primesByScheduledSlotId,
        flowRelationsByFlowId,
        primes,
      } = getData({
        semantics,
        flowRelations,
        days: journey.days,
        scheduledSlots,
      });

      const publishedByDayId = Object.fromEntries(
        journey.days.map(({ id, day }) => {
          const { step } = journey.steps.reduce(
            (total, currentStep) => {
              const totalDays = total.days + currentStep.days;
              if (day <= totalDays && day > total.days) {
                return { step: currentStep, days: totalDays };
              }
              return {
                step: total.step,
                days: totalDays,
              };
            },
            { days: 0 }
          );

          return [id, step?.isPublished ?? false];
        })
      );

      primesByDays = compose(
        fpMap((prime) => ({
          "Published Status": prime.isPublished ? "published" : "unpublished",
          Day: prime.dayTitle,
          Title: prime.title,
          Text: prime.text,
          "CTA 1": prime.cta1Text,
          "CTA 1 Redirect": getCTAType({
            ctaLinkId: prime.cta1LinkId,
            ctaTarget: prime.cta1Target,
            materialsById,
            semanticsById,
            type: prime.cta1Type,
          }),
          "CTA 2": prime.cta2Text,
          "CTA 2 Redirect": getCTAType({
            ctaLinkId: prime.cta2LinkId,
            ctaTarget: prime.cta2Target,
            materialsById,
            semanticsById,
            type: prime.cta2Type,
          }),
        })),
        fpOrderBy(
          ["isPublished", "relationType", "day", "isFirstInFlow"],
          ["desc", "asc", "asc", "desc"]
        )
      )([
        ...Object.values(primesByDays)
          .flat(1)
          .map((prime) => ({
            ...prime,
            relationType: 0,
            day: prime.day,
            dayTitle: `scheduled - day ${prime.day}`,
            isPublished: publishedByDayId[prime.dayId],
          })),
        ...Object.values(primesByScheduledSlotId)
          .flat(1)
          .map((prime) => ({
            ...prime,
            day: prime.date,
            dayTitle: `scheduled - ${prime.date}`,
            relationType: 1,
            isPublished: true,
          })),
        ...primes
          .filter(({ flowId }) => !flowRelationsByFlowId[flowId])
          .map((prime) => ({
            ...prime,
            dayTitle: "not associated to a day",
            isPublished: true,
          })),
      ]);

      const triggersByStimulusId = keyBy(triggers, "stimulusId");
      const nudges = compose(
        fpMap((nudge) => ({
          Trigger: triggersByStimulusId[nudge.stimulusId].name,
          Text: nudge.text,
          CTA: nudge.cta1Text,
          "CTA Redirect": getCTAType({
            ctaLinkId: nudge.cta1LinkId,
            ctaTarget: nudge.cta1Target,
            materialsById,
            semanticsById,
            type: nudge.cta1Type,
          }),
        })),
        fpFilter(({ stimulusId }) => triggersByStimulusId[stimulusId]),
        fpFilter("stimulusId"),
        fpFilter(["type", "NUDGE"])
      )(semantics);

      const tipsWorksheet = XLSX.utils.json_to_sheet(
        primesByDays.length
          ? primesByDays
          : [
              {
                "Published Status": "",
                Day: "",
                Title: "",
                Text: "",
                "CTA 1": "",
                "CTA 1 Redirect": "",
                "CTA 2": "",
                "CTA 2 Redirect": "",
              },
            ]
      );
      const nudgesWorksheet = XLSX.utils.json_to_sheet(
        nudges.length ? nudges : [{ Trigger: "", Text: "", CTA: "" }]
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, tipsWorksheet, "Tips");
      XLSX.utils.book_append_sheet(workbook, nudgesWorksheet, "Nudges");
      XLSX.writeFile(
        workbook,
        `${snakeCase(
          journey.name
        )}-tips-and-nudges-${new Date().toISOString()}.xlsx`
      );
    },
    [journeys]
  );

  const authSignOut = useCallback(async () => {
    try {
      const signoutReply = await authSignOutApi();
      if (signoutReply.status !== "ok") {
        throw new Error(signoutReply?.message);
      }
      if (signoutReply.hrefTo) {
        window.location.href = signoutReply.hrefTo;
      }
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(`Error signing out; please retry`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar]);

  const authRefresh = useCallback(async () => {
    try {
      const refreshReply = await authRefreshApi();
      if (refreshReply.status !== "ok" && refreshReply.hrefTo) {
        enqueueSnackbar(
          `Error refreshing session; redirecting to login in 5 seconds`,
          {
            variant: "error",
          }
        );
        setTimeout(function () {
          window.location.href = refreshReply.hrefTo;
        }, 5000);
      } else {
        const expUtcTokenCookie4localhost = refreshReply.expUtcTokenCookie;
        if (expUtcTokenCookie4localhost)
          document.cookie = expUtcTokenCookie4localhost;

        const expUtcToken = getCookie("expUtcToken");
        setTimeout(function () {
          authRefresh();
        }, Number(expUtcToken) - new Date().getTime());
      }
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(`Error refreshing session; please logout and login`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar]);

  // used to embed powerbi reports
  const pbiGetConfigCall = useCallback(
    async (reportSelector, elementOfInterest, newkey) => {
      try {
        const newlyReceivedConfig = await pbiGetConfigCallApi({
          elementOfInterest,
          reportSelector,
        });
        setPowerbiEmbedConfig((powerbiEmbedConfig) => {
          powerbiEmbedConfig[newkey] = newlyReceivedConfig;
          return powerbiEmbedConfig;
        });
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Report access error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );
  const fetchBookingEventsConditions = useCallback(
    async (userid, bookingid) => {
      try {
        const newBookingEventsConditions = await listBookingEventsConditionsApi(
          userid,
          bookingid
        );
        setBookingEventsConditions((prevConditions) => ({
          ...prevConditions,
          [`${userid}-${bookingid}`]: newBookingEventsConditions,
        }));
        return newBookingEventsConditions;
      } catch (error) {
        console.error("Error fetching booking events conditions:", error);
        enqueueSnackbar(
          `Error fetching booking events conditions: ${error.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [enqueueSnackbar]
  );
  const linkJourneys = useCallback(
    async (list) => {
      try {
        for (const item of list) {
          await linkJourneysApi({
            journeyAId: item.journeyAId,
            journeyBId: item.journeyBId,
          });
        }
        setLinkedJourneys((linkedJourneys) => [...linkedJourneys, ...list]);
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Link journeys error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const unlinkJourneys = useCallback(
    async (list) => {
      try {
        for (const item of list) {
          await unlinkJourneysApi({
            journeyAId: item.journeyAId,
            journeyBId: item.journeyBId,
          });
        }
        setLinkedJourneys((linkedJourneys) =>
          differenceWith(
            linkedJourneys,
            list,
            (a, b) =>
              a.journeyAId === b.journeyAId && a.journeyBId === b.journeyBId
          )
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Unlink journeys error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const deleteJourney = useCallback(
    async ({ journeyId, password }) => {
      try {
        const {
          materials,
          semantics,
          triggers,
          groups,
          linkedJourneys,
          scheduledSlots,
          flowRelations,
          surveys,
          triggerConfigs,
        } = await deleteJourneyApi({ journeyId, password });
        enqueueSnackbar(
          `Deleted: ${semantics} semantics, ${materials} materials, ${triggers} triggers, ${groups} groups, ${linkedJourneys} links to journeys, ${scheduledSlots} scheduled slots, ${flowRelations} flowRelations, ${surveys} surveys, ${triggerConfigs} triggerConfigs`,
          {
            variant: "success",
          }
        );

        setJourneys((journeys) =>
          journeys.filter(({ id }) => id !== journeyId)
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Delete journey error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const updateJourneyGroup = useCallback(
    async ({ isNew, ...group }) => {
      const groupsSnapshot = [..._groups];
      try {
        setGroups(_update(_groups, group));
        const newGroup = await (isNew
          ? createJourneyGroupApi(group)
          : updateJourneyGroupApi(group));
        setGroups(_update(_groups, newGroup, { isMerge: false }));
      } catch (e) {
        console.error(e.message);
        setGroups(groupsSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} group error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [_update, enqueueSnackbar, _groups]
  );

  const copySemanticFlow = useCallback(
    async ({ journeyId, flowId, formData }) => {
      try {
        const response = await copySemanticFlowApi({
          journeyId,
          flowId,
          formData,
        });
        enqueueSnackbar("Semantic flow successfully copied", {
          variant: "success",
        });
        return response;
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Error copying the semantic flow`, {
          variant: "error",
        });
        return null;
      }
    },
    [enqueueSnackbar]
  );

  const updateMultipleJourneyGroups = useCallback(
    async (groups) => {
      const groupsSnapshot = [..._groups];
      try {
        const optimisticUpdatedGroups = groups.reduce(
          (total, { isNew, ...group }) => _update(total, group),
          [..._groups]
        );
        setGroups(optimisticUpdatedGroups);

        const updatedGroups = await Promise.all(
          groups.map(({ isNew, ...group }) =>
            isNew ? createJourneyGroupApi(group) : updateJourneyGroupApi(group)
          )
        );

        const newGroups = updatedGroups.reduce(
          (total, group) => _update(total, group, { isMerge: false }),
          [..._groups]
        );
        setGroups(newGroups);
      } catch (e) {
        console.error(e.message);
        setGroups(groupsSnapshot);
        enqueueSnackbar("Update/create groups error", {
          variant: "error",
        });
      }
    },
    [_update, enqueueSnackbar, _groups]
  );

  const updateSpecialLink = useCallback(
    async ({ isNew, ...specialLink }) => {
      const specialLinksSnapshot = [...specialLinks];
      try {
        setSpecialLinks(_update(specialLinks, specialLink));
        const newSpecialLink = await (isNew
          ? createSpecialLinkApi(specialLink)
          : updateSpecialLinkApi(specialLink));
        setSpecialLinks(
          _update(specialLinks, newSpecialLink, { isMerge: false })
        );
        return newSpecialLink;
      } catch (e) {
        console.error(e.message);
        setSpecialLinks(specialLinksSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} special link error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [specialLinks, _update, enqueueSnackbar]
  );
  const updateTipGroup = useCallback(
    async ({ isNew, ...tipGroup }) => {
      const tipGroupsSnapshot = [...tipGroups];
      try {
        setTipGroups(_update(tipGroups, tipGroup));
        const newTipGroup = await (isNew
          ? createTipGroupApi(tipGroup)
          : updateTipGroupApi(tipGroup));
        setTipGroups(_update(tipGroups, newTipGroup, { isMerge: false }));
        return newTipGroup;
      } catch (e) {
        console.error(e.message);
        setTipGroups(tipGroupsSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} tip group error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [tipGroups, _update, enqueueSnackbar]
  );

  const updateTriggerConfig = useCallback(
    async (config) => {
      const configsSnapshot = [...triggerConfigs];
      try {
        setSpecialLinks(_update(triggerConfigs, config));
        const newTriggerConfig = await updateJourneyTriggerConfigApi(config);
        setTriggerConfigs(
          _update(triggerConfigs, newTriggerConfig, { isMerge: false })
        );
        return newTriggerConfig;
      } catch (e) {
        console.error(e.message);
        setTriggerConfigs(configsSnapshot);
        enqueueSnackbar(`Update-trigger-config error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [specialLinks, _update, enqueueSnackbar]
  );

  const deleteJourneyGroup = useCallback(
    async ({ id }) => {
      try {
        await deleteJourneyGroupApi({ id });

        setGroups((groups) => _update(groups, { id }, { isDelete: true }));
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete group error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const deleteSpecialLink = useCallback(
    async ({ id }) => {
      try {
        await deleteSpecialLinkApi({ id });

        setSpecialLinks((specialLinks) =>
          _update(specialLinks, { id }, { isDelete: true })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete special link error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const deleteTipGroup = useCallback(
    async ({ id }) => {
      try {
        await deleteTipGroupApi({ id });

        setTipGroups((tipGroups) =>
          _update(tipGroups, { id }, { isDelete: true })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete tip group error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const createFlowRelation = useCallback(
    async (flowRelation) => {
      const flowRelationsSnapshot = [...flowRelations];
      try {
        setFlowRelations([...flowRelations, flowRelation]);
        const newFlowRelation = await createFlowRelationApi(flowRelation);
        setFlowRelations([...flowRelations, newFlowRelation]);
        return newFlowRelation;
      } catch (e) {
        console.error(e.message);
        setFlowRelations(flowRelationsSnapshot);
        enqueueSnackbar(`Create flow relation error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [flowRelations, enqueueSnackbar]
  );

  const deleteFlowRelation = useCallback(
    async (flowRelation) => {
      try {
        await deleteFlowRelationApi(flowRelation);

        setFlowRelations((flowRelations) =>
          flowRelations.filter(
            ({ flowId, journeyId, relationId }) =>
              journeyId !== flowRelation.journeyId ||
              flowId !== flowRelation.flowId ||
              relationId !== flowRelation.relationId
          )
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete flow relation error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const updateScheduledSlot = useCallback(
    async ({ isNew, journeyId, scheduledSlot }) => {
      const scheduledSlotsSnapshot = [...scheduledSlots];
      try {
        setScheduledSlots(_update(scheduledSlots, scheduledSlot));
        const newScheduleSlot = await (isNew
          ? createScheduledSlotApi({ scheduledSlot, journeyId })
          : updateScheduledSlotApi(scheduledSlot));
        setScheduledSlots(
          _update(scheduledSlots, newScheduleSlot, { isMerge: false })
        );
        return newScheduleSlot;
      } catch (e) {
        console.error(e.message);
        setScheduledSlots(scheduledSlotsSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} scheduled slot error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [scheduledSlots, _update, enqueueSnackbar]
  );

  const deleteScheduledSlot = useCallback(
    async ({ id }) => {
      try {
        await deleteScheduledSlotApi({ id });

        setScheduledSlots((scheduledSlots) =>
          _update(scheduledSlots, { id }, { isDelete: true })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete scheduled slot error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const callAiService = useCallback(
    async (input) => {
      try {
        const result = postAiServiceHandler(input);
        return result;
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar(`Ai service call error: ${e.message}`, {
          variant: "error",
        });
      }
    },
    [postAiServiceHandler, enqueueSnackbar]
  );

  const updateConfiguration = useCallback(
    async ({ isNew, ...configuration }) => {
      const configurationsSnapshot = [...configurations];
      const configurationSnapshot = { ...configuration };
      try {
        setConfiguration((oldConfiguration) => ({
          ...oldConfiguration,
          ...configuration,
        }));
        setConfigurations(
          _update(configurations, configuration, {
            createAtTheBeginning: true,
            primaryColumn: "cid",
          })
        );
        const newConfiguration = await (isNew
          ? createConfigurationApi(configuration)
          : updateConfigurationApi(configuration));
        setConfigurations(
          _update(configurations, configuration, {
            createAtTheBeginning: true,
            isMerge: false,
            primaryColumn: "cid",
          })
        );
        setConfiguration(newConfiguration);
        return newConfiguration;
      } catch (e) {
        console.error(e.message);
        setConfigurations(configurationsSnapshot);
        setConfiguration(configurationSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} configuration error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [configurations, _update, enqueueSnackbar]
  );

  const deleteConfiguration = useCallback(
    async ({ cid }) => {
      try {
        await deleteConfigurationApi({ cid });

        setConfigurations((configurations) =>
          _update(
            configurations,
            { cid },
            { isDelete: true, primaryColumn: "cid" }
          )
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete configuration error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const updatePrompt = useCallback(
    async ({ isNew, ...prompt }, section) => {
      const promptsSnapshot = [...prompts];
      const promptSnapshot = { ...prompt };
      try {
        setPrompt((oldPrompt) => ({
          ...oldPrompt,
          ...prompt,
        }));
        setPrompts(
          _update(prompts, prompt, {
            createAtTheBeginning: true,
            primaryColumn: "id",
          })
        );
        const newPrompt = await (isNew
          ? createPromptApi(prompt, section)
          : updatePromptApi(prompt, section));
        setPrompts(
          _update(prompts, prompt, {
            createAtTheBeginning: true,
            isMerge: false,
            primaryColumn: "id",
          })
        );
        setPrompt(newPrompt);
        return newPrompt;
      } catch (e) {
        console.error(e.message);
        setPrompts(promptsSnapshot);
        setPrompt(promptSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} prompt error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [prompts, _update, enqueueSnackbar]
  );

  const deletePrompt = useCallback(
    async ({ id }, section) => {
      try {
        await deletePromptApi({ id }, section);

        setPrompts((prompts) =>
          _update(prompts, { id }, { isDelete: true, primaryColumn: "id" })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete prompt error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const updateTool = useCallback(
    async ({ isNew, ...tool }, section) => {
      const toolsSnapshot = [...tools];
      const toolSnapshot = { ...tool };
      try {
        setTool((oldTool) => ({
          ...oldTool,
          ...tool,
        }));
        setTools(
          _update(tools, tool, {
            createAtTheBeginning: true,
            primaryColumn: "id",
          })
        );
        const newTool = await (isNew
          ? createToolApi(tool, section)
          : updateToolApi(tool, section));
        setTools(
          _update(tools, tool, {
            createAtTheBeginning: true,
            isMerge: false,
            primaryColumn: "id",
          })
        );
        setTool(newTool);
        return newTool;
      } catch (e) {
        console.error(e.message);
        setTools(toolsSnapshot);
        setTool(toolSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} tool error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [tools, _update, enqueueSnackbar]
  );

  const deleteTool = useCallback(
    async ({ id }, section) => {
      try {
        await deleteToolApi({ id }, section);

        setTools((tools) =>
          _update(tools, { id }, { isDelete: true, primaryColumn: "id" })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete tool error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );
  const updateTag = useCallback(
    async ({ isNew, ...tag }, section) => {
      const tagsSnapshot = [...tags];
      // const promptSnapshot = [...prompt];
      const tagSnapshot = { ...tag };
      try {
        setTag((oldTag) => ({
          ...oldTag,
          ...tag,
        }));
        setTags(
          _update(tags, tag, {
            createAtTheBeginning: true,
            primaryColumn: "id",
          })
        );
        const newTag = await (isNew
          ? createTagApi(tag, section)
          : updateTagApi(tag, section));
        setTags(
          _update(tags, tag, {
            createAtTheBeginning: true,
            isMerge: false,
            primaryColumn: "id",
          })
        );
        setTag(newTag);
        return newTag;
      } catch (e) {
        console.error(e.message);
        setTags(tagsSnapshot);
        setTag(tagSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} tag error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [tags, _update, enqueueSnackbar]
  );
  const deleteTag = useCallback(
    async ({ id }, section) => {
      try {
        await deleteTagApi({ id }, section);

        setTags((tags) =>
          _update(tags, { id }, { isDelete: true, primaryColumn: "id" })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete tag error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );
  const updateUser = useCallback(
    async ({ isNew, ...cpuser }) => {
      const usersSnapshot = [...users];
      const userSnapshot = { ...cpuser };
      try {
        setCPuser((oldUser) => ({
          ...oldUser,
          ...cpuser,
        }));
        setUsers(
          _update(users, cpuser, {
            createAtTheBeginning: true,
            primaryColumn: "id",
          })
        );
        const newUser = await (isNew
          ? createUserApi(cpuser)
          : updateUserApi(cpuser));
        setUsers(
          _update(users, cpuser, {
            createAtTheBeginning: true,
            isMerge: false,
            primaryColumn: "id",
          })
        );
        setCPuser(newUser);
        return newUser;
      } catch (e) {
        console.error(e.message);
        setConfigurations(usersSnapshot);
        setConfiguration(userSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} user error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [users, _update, enqueueSnackbar]
  );

  const updateCompany = useCallback(
    async ({ isNew, ...company }) => {
      const companiesSnapshot = [...companies];
      const companySnapshot = { ...company };
      try {
        setCompany((oldCompany) => ({
          ...oldCompany,
          ...company,
        }));
        setCompanies(
          _update(companies, company, {
            createAtTheBeginning: true,
            primaryColumn: "id",
          })
        );
        const newCompany = await (isNew
          ? createCompanyApi(company)
          : updateCompanyApi(company));
        setCompanies(
          _update(companies, company, {
            createAtTheBeginning: true,
            isMerge: false,
            primaryColumn: "id",
          })
        );
        setCompany(newCompany);
        return newCompany;
      } catch (e) {
        console.error(e.message);
        setConfigurations(companiesSnapshot);
        setConfiguration(companySnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} company error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [companies, _update, enqueueSnackbar]
  );

  const deleteUser = useCallback(
    async ({ id }) => {
      try {
        await deleteUserApi({ id });

        setUsers((users) =>
          _update(users, { id }, { isDelete: true, primaryColumn: "id" })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete user error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const deleteCompany = useCallback(
    async ({ id }) => {
      try {
        await deleteCompanyApi({ id });

        setCompanies((companies) =>
          _update(companies, { id }, { isDelete: true, primaryColumn: "id" })
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete company error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const updateUserStatePath = useCallback(
    async ({ isNew, ...userStatePath }) => {
      const userStatePathsSnapshot = [...userStatePaths];
      try {
        // setUserStatePaths(
        //   _update(userStatePaths, userStatePath, {
        //     createAtTheBeginning: true,
        //     primaryColumn: "name",
        //   })
        // );
        const newUserStatePath = await (isNew
          ? createUserStatePathApi(userStatePath)
          : updateUserStatePathApi(userStatePath));
        setUserStatePaths(
          _update(userStatePaths, newUserStatePath, {
            createAtTheBeginning: true,
            isMerge: false,
            primaryColumn: "name",
          })
        );
        return newUserStatePath;
      } catch (e) {
        console.error(e.message);
        setUserStatePaths(userStatePathsSnapshot);
        enqueueSnackbar(
          `${isNew ? "Create" : "Update"} user state paths error: ${e.message}`,
          {
            variant: "error",
          }
        );
      }
    },
    [userStatePaths, _update, enqueueSnackbar]
  );

  const deleteUserStatePath = useCallback(
    async ({ id }) => {
      try {
        await deleteUserStatePathApi({ id });

        setUserStatePaths((userStatePaths) =>
          _update(
            userStatePaths,
            { name: id },
            { isDelete: true, primaryColumn: "name" }
          )
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete user state path error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar]
  );

  const fetchAssociatedWelfareEntity = useCallback(
    async ({ journey_id }) => {
      try {
        if (!journey_id) {
          return;
        }
        const associatedWelfareEntities =
          await fetchAssociatedWelfareEntityHandler(journey_id);
        const associatedEntity = associatedWelfareEntities?.[0]?.legalentity_id;
        setAssociatedWelfareEntity(associatedEntity ?? null);
      } catch (e) {
        console.error("Fetch associated welfare entity", e.message);
        setAssociatedWelfareEntity(null);
      }
    },
    [fetchAssociatedWelfareEntityHandler]
  );

  const fetchJourneyBenefits = useCallback(
    async ({ journey_id }) => {
      try {
        if (!journey_id) {
          return;
        }
        const jbenefits = await fetchJourneyBenefitsHandler({
          journey_id,
        });
        setJourneyBenefits(
          jbenefits.entityBenefits && jbenefits.entityBenefits.length
            ? jbenefits.entityBenefits.map((b) => ({
                id: b.id,
                name: b.text_default?.name || "...missing name",
                description: b.text_default?.description || "",
                entity: jbenefits.entity,
                usercansubscribe: b.usercansubscribe || false,
              }))
            : []
        );
      } catch (e) {
        console.error("Fetch journeyBenefits error ", e.message);
      }
    },
    [fetchJourneyBenefitsHandler]
  );
  const fetchJourneyNews = useCallback(
    async ({ journey_id }) => {
      try {
        if (!journey_id) {
          return;
        }
        const { entityNews } = await fetchJourneyNewsHandler({ journey_id });
        setJourneyNews(entityNews);
      } catch (e) {
        console.error("Fetch journey news error", e.message);
        setJourneyNews([]);
      }
    },
    [fetchJourneyNewsHandler]
  );

  const fetchWelfareSelector = useCallback(
    async (selectorType) => {
      try {
        if (!user || !selectorType) {
          return;
        }

        switch (selectorType) {
          case "entities": {
            const entities = await fetchWelfareSelectorsHandler("entity");
            setWelfareSelectors((prevState) => ({
              ...prevState,
              entities,
            }));
            break;
          }
          case "contractType": {
            const contract = await fetchWelfareSelectorsHandler("contract");
            setWelfareSelectors((prevState) => ({
              ...prevState,
              contractType: contract,
            }));
            break;
          }

          case "employeeRole": {
            const employeeRole = await fetchWelfareSelectorsHandler("role");
            setWelfareSelectors((prevState) => ({
              ...prevState,
              employeeRole,
            }));
            break;
          }
          case "officeType": {
            const officeType = await fetchWelfareSelectorsHandler("field");
            setWelfareSelectors((prevState) => ({
              ...prevState,
              officeType,
            }));
            break;
          }
          case "employeeType": {
            const employeeType = await fetchWelfareSelectorsHandler("employee");
            setWelfareSelectors((prevState) => ({
              ...prevState,
              employeeType,
            }));
            break;
          }
          case "officeLocation": {
            const officeLocation = await fetchWelfareSelectorsHandler(
              "location"
            );
            setWelfareSelectors((prevState) => ({
              ...prevState,
              officeLocation,
            }));
            break;
          }
          default:
            return;
        }
      } catch (e) {
        console.error("Fetch" + selectorType, e.message);
        setWelfareSelectors((prevState) => ({
          ...prevState,
          [selectorType]: [],
        }));
      }
    },
    [fetchWelfareSelectorsHandler, user]
  );

  const fetchBenefit = useCallback(
    async (id) => {
      try {
        if (!user) {
          return;
        }

        const benefit = await fetchBenefitHandler(id);
        //setBenefit(benefit[0] ?? []);
        return benefit[0];
      } catch (e) {
        console.error("Fetch benefit", e.message);
        setBenefit(null);
      }
    },
    [fetchBenefitHandler, user]
  );

  const fetchAllBenefits = useCallback(async () => {
    try {
      if (!user) {
        return;
      }
      const benefits = await fetchAllBenefitsHandler();
      setBenefits(benefits);
    } catch (e) {
      console.error("Fetch all benefits", e.message);
      setBenefits([]);
    }
  }, [fetchAllBenefitsHandler, user]);

  const fetchWelfareCategories = useCallback(async () => {
    try {
      if (!user) {
        return;
      }
      const categories = await fetchWelfareCategoriesHandler();
      setWelfareCategories(categories);
    } catch (e) {
      console.error("Fetch welfare categories", e.message);
      setWelfareCategories([]);
    }
  }, [fetchWelfareCategoriesHandler, user]);

  const fetchWelfareSubcategories = useCallback(
    async (categoryId = null) => {
      try {
        if (!user) {
          return;
        }
        const categories = await fetchWelfareSubcategoriesHandler(categoryId);
        setWelfareSubcategories(categories);
      } catch (e) {
        console.error("Fetch welfare subcategories", e.message);
        setWelfareSubcategories([]);
      }
    },
    [fetchWelfareSubcategoriesHandler, user]
  );

  const fetchWelfareBookings = useCallback(async () => {
    try {
      if (!user) {
        return;
      }
      const bookings = await fetchWelfareBookingsHandler();
      setWelfareBookings(bookings);
    } catch (e) {
      console.error("Fetch welfare bookings", e.message);
      setWelfareBookings([]);
    }
  }, [fetchWelfareBookingsHandler, user]);

  const updateWelfareBooking = useCallback(
    async ({ isNew, ...obj }) => {
      const bookingsSnapshot = [...welfareBookings];
      try {
        const newBookings = await (isNew
          ? createWelfareBookingApi(obj)
          : updateWelfareBookingApi(obj));
        setWelfareBookings(
          (prevBookings) => _update(prevBookings, newBookings),
          {
            isMerge: false,
          }
        );
        enqueueSnackbar(
          `Booking ${isNew ? "created" : "updated"} successfully`,
          {
            variant: "success",
          }
        );
        return newBookings;
      } catch (e) {
        setWelfareBookings(bookingsSnapshot);
        enqueueSnackbar(`${isNew ? "Create" : "Update"} booking error`, {
          variant: "error",
        });
      }
    },
    [_update, enqueueSnackbar, welfareBookings]
  );
  const deleteWelfareBooking = useCallback(
    async ({ ...welfareBooking }) => {
      try {
        await updateWelfareBookingApi(welfareBooking);
        setWelfareBookings(
          _update(
            welfareBookings,
            { id: welfareBooking.id },
            { isdeleted: true }
          )
        );
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete boking error", { variant: "error" });
      }
    },
    [_update, welfareBookings, enqueueSnackbar]
  );

  const updateWelfareBenefit = useCallback(
    async ({ isNew, ...benefit }) => {
      const benefitsSnapshot = [...benefits];
      try {
        const newBenefit = await (isNew
          ? createWelfareBenefitApi(benefit)
          : updateWelfareBenefitApi(benefit));
        setBenefits((prevBenefits) => _update(prevBenefits, newBenefit), {
          isMerge: false,
        });
        return newBenefit;
      } catch (e) {
        setBenefits(benefitsSnapshot);
        enqueueSnackbar(`${isNew ? "Create" : "Update"} benefit error`, {
          variant: "error",
        });
      }
    },
    [_update, enqueueSnackbar, benefits]
  );

  const deleteWelfareBenefit = useCallback(
    async ({ ...benefit }) => {
      try {
        await updateWelfareBenefitApi(benefit);
        setBenefits(_update(benefits, { id: benefit.id }, { isDelete: true }));
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete benefit error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar, benefits]
  );

  const fetchAngeliniUsers = useCallback(
    async (body) => {
      try {
        if (!user || !body) {
          return [];
        }
        const users = await fetchAngeliniUsersHandler(body);
        return users;
      } catch (e) {
        console.error("Fetch angelini users", e.message);
        return [];
      }
    },
    [fetchAngeliniUsersHandler, user]
  );

  const updateWelfareNews = useCallback(
    async ({ isNew, ...obj }) => {
      const newsSnapshot = [...news];
      try {
        const newNews = await (isNew
          ? createWelfareNewsApi(obj)
          : updateWelfareNewsApi(obj));
        setNews((prevNews) => _update(prevNews, newNews), {
          isMerge: false,
        });
        enqueueSnackbar(`News ${isNew ? "created" : "updated"} successfully`, {
          variant: "success",
        });
        return newNews;
      } catch (e) {
        setNews(newsSnapshot);
        enqueueSnackbar(`${isNew ? "Create" : "Update"} news error`, {
          variant: "error",
        });
      }
    },
    [_update, enqueueSnackbar, news]
  );

  const deleteWelfareNews = useCallback(
    async ({ ...obj }) => {
      try {
        await updateWelfareNewsApi(obj);
        setNews(_update(news, { id: obj.id }, { isDelete: true }));
        enqueueSnackbar("News deleted", { variant: "success" });
      } catch (e) {
        console.error(e.message);
        enqueueSnackbar("Delete news error", { variant: "error" });
      }
    },
    [_update, enqueueSnackbar, news]
  );

  const fetchNews = useCallback(
    async (id) => {
      try {
        if (!user) {
          return;
        }
        const news = await fetchNewsHandler(id);
        return news[0];
      } catch (e) {
        console.error("Fetch news", e.message);
      }
    },
    [fetchNewsHandler, user]
  );

  const fetchAllNews = useCallback(async () => {
    try {
      if (!user) {
        return;
      }
      const news = await fetchAllNewsHandler();
      setNews(news);
    } catch (e) {
      console.error("Fetch all news", e.message);
      setBenefits([]);
    }
  }, [fetchAllNewsHandler, user]);

  return (
    <AppContext.Provider
      value={{
        user,
        cpuser,
        setUser,
        setCompany,
        company,

        journeys,
        isJourneysLoading,
        fetchJourneys,

        materials,
        isMaterialsLoading,
        fetchMaterials,

        uiConfigs,
        isUIConfigsLoading,
        fetchUIConfigs,

        semantics,
        isSemanticsLoading,
        isAllSemanticsForUserLoading,
        templateFamilies,
        teamsTemplateFamilies,
        semanticTemplates,
        teamsSemanticTemplates,
        semanticPreviewTemplates,
        isTemplateFamiliesLoading,
        isTeamsTemplateFamiliesLoading,
        isSemanticTemplatesLoading,
        isTeamsSemanticTemplatesLoading,
        isSemanticPreviewTemplatesLoading,
        fetchSemantics,
        fetchAllSemanticsForUser,
        fetchTemplateFamilies,
        fetchTeamsTemplateFamilies,
        fetchSemanticTemplates,
        fetchTeamsSemanticTemplates,
        fetchSemanticPreviewTemplates,
        exportSemantics,

        deleteJourney,
        updateJourney,
        updateJourneyMaterial,
        deleteJourneyMaterial,
        updateJourneySemantic,
        deleteJourneySemanticsByDay,
        deleteJourneySemanticsByFlow,
        deleteJourneySemantic,
        deleteJourneyStep,

        otherJourneyTriggers,

        triggers,
        fetchTriggers,
        isTriggersLoading,
        updateTrigger,
        deleteTrigger,

        isAllTriggersLoading,
        fetchAllTriggers,

        // companyUsers,
        // fetchCompanyUsers,
        // isCompanyUsersLoading,

        companies,
        fetchCompanies,
        isCompaniesLoading,

        isCopyJourneyLoading,
        copyJourney,

        // triggersAppLink,
        // fetchTriggersAppLink,
        // isTriggersAppLinkLoading,

        authSignOut,
        authRefresh,
        pbiGetConfigCall,
        powerbiEmbedConfig,

        isLinkedJourneysLoading,
        linkedJourneys,
        fetchLinkedJourneys,
        linkJourneys,
        unlinkJourneys,

        isGroupsLoading,
        fetchGroups,
        groups,
        updateJourneyGroup,
        copySemanticFlow,
        updateMultipleJourneyGroups,
        deleteJourneyGroup,

        isSpecialLinksLoading,
        specialLinks,
        fetchSpecialLinks,
        updateSpecialLink,
        deleteSpecialLink,

        isTipGroupsLoading,
        tipGroups,
        fetchTipGroups,
        updateTipGroup,
        deleteTipGroup,

        fetchTriggerConfigs,
        isTriggerConfigsLoading,
        triggerConfigs,
        updateTriggerConfig,

        isShowHidden,
        setIsShowHidden,

        fetchJourneySemanticsWithEmptyCTALink,
        journeySemanticsWithEmptyCTALink,
        isJourneySemanticsWithEmptyCTALinkLoading,

        flowRelations,
        fetchFlowRelations,
        isFlowRelationsLoading,
        createFlowRelation,
        deleteFlowRelation,

        scheduledSlots,
        fetchScheduledSlots,
        isScheduledSlotsLoading,
        updateScheduledSlot,
        deleteScheduledSlot,

        configurations,
        users,
        prompts,
        prompt,
        seeds,
        aiSkillingPrompts,
        tools,
        tool,
        tags,
        tag,
        configuration,
        fetchConfigurations,
        fetchConfiguration,

        fetchPrompts,
        updatePrompt,
        deletePrompt,

        fetchTools,
        updateTool,
        deleteTool,

        fetchTags,
        updateTag,
        deleteTag,

        fetchUsers,
        fetchUser,
        fetchCompany,
        isConfigurationLoading,
        isConfigurationsLoading,
        isPromptsLoading,
        isToolsLoading,
        isTagsLoading,
        callAiService,
        isAiServiceLoading,
        isUserLoading,
        isCompanyLoading,
        isUsersLoading,
        updateConfiguration,
        deleteConfiguration,
        updateUser,
        deleteUser,
        updateCompany,
        deleteCompany,

        isUserStatePathsLoading,
        fetchUserStatePaths,
        updateUserStatePath,
        deleteUserStatePath,
        userStatePaths,

        surveys,
        survey,
        isSurveysLoading,
        isSurveyLoading,
        fetchSurveys,
        fetchSurvey,
        updateSurvey,
        deleteSurvey,

        isAllSurveysForUserLoading,
        fetchAllSurveysForUser,

        typeform,
        isTypeformLoading,
        fetchTypeform,

        fetchIntegrations,
        exportImage,
        updateIntegration,
        deleteIntegration,
        isIntegrationsLoading,
        integrations,

        fetchTypeforms,
        isTypeformsLoading,
        typeforms,

        welfareSelectors,
        isWelfareSelectorsLoading,
        fetchWelfareSelector,

        associatedWelfareEntity,
        isAssociatedWelfareEntityLoading,
        fetchAssociatedWelfareEntity,

        benefit,
        isBenefitLoading,
        fetchBenefit,

        benefits,
        isAllBenefitsLoading,
        fetchAllBenefits,

        journeyBenefits,
        isListJourneyBenefitsLoading,
        fetchJourneyBenefits,

        fetchJourneyNews,
        isListJourneyNewsLoading,
        journeyNews,

        welfareCategories,
        isWelfareCategoriesLoading,
        fetchWelfareCategories,

        welfareSubcategories,
        isWelfareSubcategoriesLoading,
        fetchWelfareSubcategories,

        welfareBookings,
        isWelfareBookingsLoading,
        fetchWelfareBookings,
        updateWelfareBooking,
        deleteWelfareBooking,

        updateWelfareBenefit,
        deleteWelfareBenefit,

        isAngeliniUsersLoading,
        fetchAngeliniUsers,

        isNewsLoading,
        fetchNews,

        news,
        isAllNewsLoading,
        fetchAllNews,

        updateWelfareNews,
        deleteWelfareNews,

        fetchBookingEventsConditions,
        bookingEventsConditions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
