import React, { useCallback, useEffect, useMemo, useState } from "react";
import invert from "lodash/invert";
import styled from "styled-components/macro";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import MuiTab from "@material-ui/core/Tab";
import MuiToolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import SpaIcon from "@material-ui/icons/Spa";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import ForumIcon from "@material-ui/icons/Forum";
import SignOutIcon from "../icons/SignOutIcon";
import { useAppContext } from "../api/AppContext";
import SemanticsSearch from "./Journey/SemanticsSearch";
import WelfareSearch from "./Welfares/WelfareSearch";

const ENIKNOWLEDGEHUB_COMPANY_ID = "f4a3cc35-ad56-47dc-92db-840e6ec08cfb";
//const ALLITUDE_COMPANY_ID = "";

const SignOutButton = withStyles({
  root: {
    fontSize: "0.875rem",
    fontWeight: "normal",
    whiteSpace: "nowrap",
  },
})(Button);

const ProfileAvatar = withStyles({
  root: {
    backgroundColor: "#F2E7FE",
    backgroundImage: "url(/avatar-background.png)",
    backgroundSize: "cover",
    color: "#681CE3",
  },
})(Avatar);

const Tab = withStyles({
  root: {
    color: "#212121",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "unset",
    lineHeight: "16px",
    minWidth: "fit-content",
    textTransform: "none",
    whiteSpace: "nowrap",
  },
})(MuiTab);

const Toolbar = withStyles(({ spacing }) => ({
  root: {
    minHeight: 48,
    paddingLeft: spacing(7.5),
    paddingRight: spacing(7.5),
  },
}))(MuiToolbar);

const ToolbarContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const PlanName = withStyles({
  root: {
    minWidth: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
})(Typography);

const Header = () => {
  const {
    user,
    authSignOut,
    journeys,
    isSemanticsLoading,
    setIsShowHidden,
    configuration,
  } = useAppContext();
  const theme = useTheme();

  const history = useHistory();
  const location = useLocation();
  const paths = location.pathname.split("/");
  // Remove nudges and learning materials option for angelini users
  const tabs =
    user.groups.includes("Admins") ||
    !user.companies?.includes("9aa46b2c-5fa6-4f0c-839d-35527dd19386")
      ? {
          0: "info",
          1: "structure-and-interactions",
          2: "scheduled-slots",
          3: "tips",
          4: "contextual",
          5: "learning-materials",
          6: "surveys",
        }
      : {
          0: "info",
          1: "structure-and-interactions",
          2: "scheduled-slots",
          3: "tips",
          // 4: "contextual",
          // 5: "learning-materials",
          4: "surveys",
        };

  const tabsInverted = invert(tabs);
  const settingsTabs = user.groups.includes("Admins")
    ? {
        0: "special-links",
        1: "user-metadata",
        2: "cid-configurations",
        3: "calldetection-configuration",
        4: "companyuser-configuration",
        5: "integrations",
        6: "tip-groups-for-analytics",
      }
    : {
        0: "integrations",
      };

  const welfaresTabs =
    user.groups.includes("Admins") ||
    user.companies?.includes("9aa46b2c-5fa6-4f0c-839d-35527dd19386")
      ? {
          0: "list",
          1: "news",
          2: "categories",
          3: "ad-properties",
          4: "welfare-impersonate",
          5: "bookings",
        }
      : {};
  const copilotTabs = user.groups.includes("Admins")
    ? {
        0: "prompt",
        1: "tools",
        2: "tags",
      }
    : {};

  const knowledgeHubTabs =
    user.groups.includes("Admins") ||
    user.companies?.includes(ENIKNOWLEDGEHUB_COMPANY_ID)
      ? {
          0: "prompt",
          1: "tools",
          2: "tags",
        }
      : {};

  const allitudeTabs = user.groups.includes("Admins") //||
    ? //user.companies?.includes(ALLITUDE_COMPANY_ID)
      {
        0: "prompt",
        1: "tools",
        2: "tags",
      }
    : {};
  const aiSkillingTabs = user.groups.includes("Admins") //||
    ? //user.companies?.includes(ENIKNOWLEDGEHUB_COMPANY_ID)
      {
        0: "prompt",
        1: "tools",
        2: "tags",
      }
    : {};
  const settingsTabsInverted = invert(settingsTabs);
  const welfaresTabsInverted = invert(welfaresTabs);
  const copilotTabsInverted = invert(copilotTabs);
  const knowledgeHubTabsInverted = invert(knowledgeHubTabs);
  const allitudeTabsInverted = invert(allitudeTabs);
  const aiSkillingTabsInverted = invert(aiSkillingTabs);

  const journeyId = useMemo(() => {
    if (paths[1] === "journeys") {
      return paths[2];
    }

    return null;
  }, [paths]);

  const locale = useMemo(() => {
    if (paths[1] === "journeys") {
      return paths[3];
    }

    return null;
  }, [paths]);

  const journeyName = useMemo(() => {
    const journey = journeys.find((journey) => journey.id === journeyId);
    return journey?.internalName || journey?.name;
  }, [journeyId, journeys]);

  const [logoClickedCounter, setLogoClickedCounter] = useState(0);
  const goToMain = useCallback(() => {
    history.push("/");

    if (logoClickedCounter + 1 === 5) {
      setIsShowHidden((isShowHidden) => !isShowHidden);
      setLogoClickedCounter(0);
      return;
    }

    setLogoClickedCounter(logoClickedCounter + 1);

    if (logoClickedCounter === 0) {
      setTimeout(() => {
        setLogoClickedCounter(0);
      }, 5000);
    }
  }, [history, logoClickedCounter, setIsShowHidden]);

  const [tab, setTab] = useState(
    parseInt(
      tabsInverted[location.pathname.split("/")[4]?.toLowerCase()] ??
        settingsTabsInverted[location.pathname.split("/")[2]?.toLowerCase()] ??
        welfaresTabsInverted[location.pathname.split("/")[2]?.toLowerCase()] ??
        copilotTabsInverted[location.pathname.split("/")[2]?.toLowerCase()] ??
        knowledgeHubTabsInverted[
          location.pathname.split("/")[2]?.toLowerCase()
        ] ??
        allitudeTabsInverted[location.pathname.split("/")[2]?.toLowerCase()] ??
        aiSkillingTabsInverted[
          location.pathname.split("/")[2]?.toLowerCase()
        ] ??
        "0",
      10
    )
  );

  useEffect(() => {
    setTab(
      parseInt(
        tabsInverted[location.pathname.split("/")[4]?.toLowerCase()] ??
          settingsTabsInverted[
            location.pathname.split("/")[2]?.toLowerCase()
          ] ??
          welfaresTabsInverted[
            location.pathname.split("/")[2]?.toLowerCase()
          ] ??
          copilotTabsInverted[location.pathname.split("/")[2]?.toLowerCase()] ??
          knowledgeHubTabsInverted[
            location.pathname.split("/")[2]?.toLowerCase()
          ] ??
          allitudeTabsInverted[
            location.pathname.split("/")[2]?.toLowerCase()
          ] ??
          aiSkillingTabsInverted[
            location.pathname.split("/")[2]?.toLowerCase()
          ] ??
          "0",
        10
      )
    );
  }, [
    aiSkillingTabsInverted,
    allitudeTabsInverted,
    copilotTabsInverted,
    knowledgeHubTabsInverted,
    location.pathname,
    settingsTabsInverted,
    tabsInverted,
    welfaresTabsInverted,
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const handleTabChange = useCallback(
    (event, newTab) => {
      const paths = location.pathname.split("/");

      if (paths[1] === "settings") {
        setTab(newTab);
        history.replace(`/settings/${settingsTabs[newTab]}`, location.state);
        return;
      }

      if (paths[1] === "welfares") {
        setTab(newTab);
        history.replace(`/welfares/${welfaresTabs[newTab]}`, location.state);
        return;
      }

      if (paths[1] === "copilot") {
        setTab(newTab);
        history.replace(`/copilot/${copilotTabs[newTab]}`, location.state);
        return;
      }
      if (paths[1] === "knowledge-hub") {
        setTab(newTab);
        history.replace(
          `/knowledge-hub/${knowledgeHubTabs[newTab]}`,
          location.state
        );
        return;
      }
      if (paths[1] === "allitude") {
        setTab(newTab);
        history.replace(`/allitude/${allitudeTabs[newTab]}`, location.state);
        return;
      }
      if (paths[1] === "aiskilling") {
        setTab(newTab);
        history.replace(
          `/aiskilling/${aiSkillingTabs[newTab]}`,
          location.state
        );
        return;
      }

      if (paths[1] !== "journeys") {
        return;
      }

      if (newTab !== 0 && location.state?.isNew) {
        enqueueSnackbar("Save Journey first", { variant: "info" });
        return;
      }

      setTab(newTab);
      history.replace(
        `/journeys/${paths[2]}/${paths[3]}/${tabs[newTab]}`,
        location.state
      );
    },
    [
      location.pathname,
      location.state,
      history,
      //tabs,
      settingsTabs,
      welfaresTabs,
      copilotTabs,
      knowledgeHubTabs,
      allitudeTabs,
      aiSkillingTabs,
      enqueueSnackbar,
    ]
  );

  const handleBack = useCallback(() => {
    if (paths[2] === "welcome-message") {
      history.push("/settings/cid-configurations");
      return;
    }

    if (paths[2] === "companyuser-configuration" && paths[3]) {
      history.push("/settings/companyuser-configuration");
      return;
    }

    if (paths[2] === "cid-configurations" && paths[3]) {
      history.push("/settings/cid-configurations");
      return;
    }

    if (paths[2] === "welcome-message") {
      history.push("/settings/cid-configurations");
      return;
    }

    if (
      ["learning-materials", "surveys"].includes(paths[4]?.toLowerCase()) &&
      paths[5]
    ) {
      // Remove learning-materials option for angelini users
      if (paths[4]?.toLowerCase() === "learning-materials") {
        if (
          user.groups.includes("Admins") ||
          !user.companies?.includes("9aa46b2c-5fa6-4f0c-839d-35527dd19386")
        ) {
          history.push(`/journeys/${paths[2]}/${paths[3]}/${paths[4]}`);
          return;
        }
        return;
      }
      history.push(`/journeys/${paths[2]}/${paths[3]}/${paths[4]}`);
      return;
    }

    if (["primes", "nudges"].includes(paths[4]?.toLowerCase())) {
      // Remove nudges option for angelini users

      if (paths[4]?.toLowerCase() === "nudges") {
        if (
          user.groups.includes("Admins") ||
          !user.companies?.includes("9aa46b2c-5fa6-4f0c-839d-35527dd19386")
        ) {
          history.push(
            `/journeys/${paths[2]}/${paths[3]}/${
              paths[4]?.toLowerCase() === "primes" ? tabs[3] : tabs[4]
            }`
          );
          return;
        } else {
          return;
        }
      }

      history.push(
        `/journeys/${paths[2]}/${paths[3]}/${
          paths[4]?.toLowerCase() === "primes" ? tabs[3] : tabs[4]
        }`
      );
      return;
    }

    if (paths[1] === "welfares") {
      if (paths[2] === "news" && paths[3]) {
        history.push("/welfares/news");
        return;
      }
      if (paths[2] && paths[2] !== "list") {
        history.push("/welfares");
        return;
      }
      history.push("/");
      return;
    }

    if (paths[1] === "copilot") {
      if (paths[2] === "prompt" && paths[3]) {
        history.push("/copilot/prompt");
        return;
      }
      if (paths[2] === "tools" && paths[3]) {
        history.push("/copilot/tools");
        return;
      }
      if (paths[2] === "tags" && paths[3]) {
        history.push("/copilot/tags");
        return;
      }
      history.push("/");
      return;
    }
    if (paths[1] === "knowledge-hub") {
      if (paths[2] === "prompt" && paths[3]) {
        history.push("/knowledge-hub/prompt");
        return;
      }
      if (paths[2] === "tools" && paths[3]) {
        history.push("/knowledge-hub/tools");
        return;
      }
      if (paths[2] === "tags" && paths[3]) {
        history.push("/knowledge-hub/tags");
        return;
      }
      history.push("/");
      return;
    }
    if (paths[1] === "allitude") {
      if (paths[2] === "prompt" && paths[3]) {
        history.push("/allitude/prompt");
        return;
      }
      if (paths[2] === "tools" && paths[3]) {
        history.push("/allitude/tools");
        return;
      }
      if (paths[2] === "tags" && paths[3]) {
        history.push("/allitude/tags");
        return;
      }
      history.push("/");
      return;
    }
    if (paths[1] === "aiskilling") {
      if (paths[2] === "prompt" && paths[3]) {
        history.push("/aiskilling/prompt");
        return;
      }
      if (paths[2] === "tools" && paths[3]) {
        history.push("/aiskilling/tools");
        return;
      }
      if (paths[2] === "tags" && paths[3]) {
        history.push("/aiskilling/tags");
        return;
      }
      history.push("/");
      return;
    }

    history.push("/");
  }, [history, paths, user.companies, user.groups]);

  return (
    <AppBar
      color="transparent"
      elevation={1}
      position="fixed"
      style={{ zIndex: theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Grid container direction="column" spacin={2}>
          <Grid item>
            <ToolbarContainer>
              {location.pathname !== "/" ? (
                <>
                  <Hidden mdDown>
                    <Button
                      disabled={
                        paths[4]?.toLowerCase() === "primes" &&
                        isSemanticsLoading
                      }
                      startIcon={
                        <ChevronLeftIcon color="action" fontSize="small" />
                      }
                      onClick={handleBack}
                    >
                      <Typography
                        color="textSecondary"
                        style={{ textTransform: "none" }}
                      >
                        Back
                      </Typography>
                    </Button>
                  </Hidden>
                  <Hidden lgUp>
                    <Tooltip title="Back">
                      <IconButton onClick={handleBack}>
                        <ChevronLeftIcon color="action" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Hidden>

                  {paths[1] === "settings" && !paths[3] && (
                    <>
                      <Divider flexItem light orientation="vertical" />
                      <Typography>Settings</Typography>
                    </>
                  )}

                  {paths[1] === "settings" &&
                    paths[2] === "cid-configurations" &&
                    paths[3] &&
                    configuration && (
                      <>
                        <Divider flexItem light orientation="vertical" />
                        <Typography>
                          {configuration.name} ({configuration.cid})
                        </Typography>
                      </>
                    )}

                  {journeyName && (
                    <>
                      <Divider flexItem light orientation="vertical" />
                      <Tooltip title={journeyName}>
                        <PlanName>{journeyName}</PlanName>
                      </Tooltip>
                    </>
                  )}
                </>
              ) : (
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to="/"
                >
                  <LogoContainer onClick={goToMain}>
                    <img alt="logo" src="/logo.png" width={24} />
                    <Typography variant="h6">
                      {process.env.REACT_APP_DASHBOARD_NAME} Portal
                    </Typography>
                  </LogoContainer>
                </Link>
              )}

              {location.pathname.startsWith("/settings") ? (
                <Box display="flex" flex={1} justifyContent="center">
                  <Tabs
                    centered
                    indicatorColor="primary"
                    value={tab}
                    variant="fullWidth"
                    onChange={handleTabChange}
                  >
                    {user.groups.includes("Admins")
                      ? [
                          <Tab
                            key="special-link"
                            wrapped
                            label="Special Link"
                          />,
                          <Tab
                            key="user-metadata"
                            wrapped
                            label="User's metadata"
                          />,
                          <Tab
                            key="cid-configuration"
                            wrapped
                            label="CID configuration"
                          />,
                          <Tab
                            key="calldetection-configuration"
                            wrapped
                            label="Call detection"
                          />,
                          <Tab
                            key="companyuser-configuration"
                            wrapped
                            label="Partners' and Customers' access"
                          />,
                        ]
                      : []}
                    <Tab wrapped label="Integrations" />
                    {user.groups.includes("Admins") &&
                    user.companies?.includes(
                      "5706867a-c563-4ca5-b803-c1883c38b640"
                    ) ? (
                      <Tab
                        key="tip-groups-for-analytics"
                        wrapped
                        label="Tip Group for analytics"
                      />
                    ) : (
                      []
                    )}
                  </Tabs>
                </Box>
              ) : location.pathname.match(
                  /^(\/welfares(?:\/(news|list|categories|ad-properties|welfare-impersonate|bookings))?\/?)$/
                ) ? (
                <>
                  <Box display="flex" flex={1} justifyContent="center">
                    <Tabs
                      centered
                      indicatorColor="primary"
                      value={tab}
                      variant="fullWidth"
                      onChange={handleTabChange}
                    >
                      {user.groups.includes("Admins") ||
                      user.companies?.includes(
                        "9aa46b2c-5fa6-4f0c-839d-35527dd19386"
                      )
                        ? [
                            <Tab key="list" wrapped label="Welfares" />,
                            <Tab key="news" wrapped label="News" />,
                            <Tab key="categories" wrapped label="Categories" />,

                            <Tab
                              key="ad-properties"
                              wrapped
                              label="Ad properties"
                            />,
                            <Tab
                              key="welfare-impersonate"
                              wrapped
                              label="Simulate"
                            />,
                            <Tab key="bookings" wrapped label="Bookings" />,
                          ]
                        : []}
                    </Tabs>
                  </Box>
                  {(user.groups.includes("Admins") ||
                    user.companies?.includes(
                      "9aa46b2c-5fa6-4f0c-839d-35527dd19386"
                    )) && (
                    <Box display="flex" flex={1} justifyContent="center">
                      <WelfareSearch />
                    </Box>
                  )}
                </>
              ) : location.pathname.startsWith("/copilot") ||
                location.pathname.startsWith("/knowledge-hub") ||
                location.pathname.startsWith("/allitude") ||
                location.pathname.startsWith("/aiskilling") ? (
                <Box display="flex" flex={1} justifyContent="center">
                  <Tabs
                    centered
                    indicatorColor="primary"
                    value={tab}
                    variant="fullWidth"
                    onChange={handleTabChange}
                  >
                    {user.groups.includes("Admins") ||
                    user.companies?.includes(ENIKNOWLEDGEHUB_COMPANY_ID) //||
                      ? /* (location.pathname.startsWith("/allitude") &&
                      user.companies?.includes(ALLITUDE_COMPANY_ID)) */
                        [
                          <Tab key="prompt" wrapped label="Prompt" />,
                          <Tab key="tools" wrapped label="Tools" />,
                          <Tab key="tags" wrapped label="Tags" />,
                        ]
                      : []}
                  </Tabs>
                </Box>
              ) : (
                <Box display="flex" flex={1} />
              )}

              {journeyId && (
                <Box display="flex" flex={1} justifyContent="center">
                  <SemanticsSearch journeyId={journeyId} locale={locale} />
                </Box>
              )}
              {/* AI Skilling Section */}
              {user.groups.includes("Admins") &&
                user.companies?.includes(
                  "5706867a-c563-4ca5-b803-c1883c38b640"
                ) && ( //||
                  //user.companies?.includes(ALLITUDE_COMPANY_ID)) && (
                  <Tooltip title="AI Skilling">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/aiskilling/prompt"
                    >
                      <IconButton size="small">
                        <ForumIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              {/* Allitude Section */}
              {user.groups.includes("Admins") &&
                user.companies?.includes(
                  "5706867a-c563-4ca5-b803-c1883c38b640"
                ) && ( //||
                  //user.companies?.includes(ALLITUDE_COMPANY_ID)) && (
                  <Tooltip title="Allitude">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/allitude/prompt"
                    >
                      <IconButton size="small">
                        <CastForEducationIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              {/* KnowledgeHub section is just for D.A. admins, or EniKnowledgeHub */}
              {((user.groups.includes("Admins") &&
                user.companies?.includes(
                  "5706867a-c563-4ca5-b803-c1883c38b640"
                )) ||
                user.companies?.includes(ENIKNOWLEDGEHUB_COMPANY_ID)) && (
                <Tooltip title="Knowledge Hub">
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="/knowledge-hub/prompt"
                  >
                    <IconButton size="small">
                      <DeviceHubIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {/* Prompt section is just for D.A. admins */}
              {user.groups.includes("Admins") &&
                user.companies?.includes(
                  "5706867a-c563-4ca5-b803-c1883c38b640"
                ) && (
                  <Tooltip title="Copilot">
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to="/copilot/prompt"
                    >
                      <IconButton size="small">
                        <EmojiObjectsIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}

              {(user.groups.includes("Admins") ||
                user.companies?.includes(
                  "9aa46b2c-5fa6-4f0c-839d-35527dd19386"
                )) && (
                <Tooltip title="Welfares">
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="/welfares"
                  >
                    <IconButton size="small">
                      <SpaIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {user.groups.includes("Admins") && (
                <Tooltip title="Setting">
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="/settings"
                  >
                    <IconButton size="small">
                      <SettingsIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              <Divider flexItem light orientation="vertical" />
              <Hidden mdDown>
                <Box>
                  <Typography>
                    {user.given_name} {user.family_name}
                  </Typography>
                </Box>
                <ProfileAvatar>
                  {user.given_name?.charAt(0).toUpperCase()}
                  {user.family_name?.charAt(0).toUpperCase()}
                </ProfileAvatar>
              </Hidden>
              <Hidden lgUp>
                <Tooltip title={`${user.given_name} ${user.family_name}`}>
                  <ProfileAvatar>
                    {user.given_name?.charAt(0).toUpperCase()}
                    {user.family_name?.charAt(0).toUpperCase()}
                  </ProfileAvatar>
                </Tooltip>
              </Hidden>
              <Divider flexItem light orientation="vertical" />
              <Hidden mdDown>
                <SignOutButton
                  color="inherit"
                  endIcon={<SignOutIcon fontSize="small" />}
                  fontSize="small"
                  onClick={authSignOut}
                >
                  Sign out
                </SignOutButton>
              </Hidden>
              <Hidden lgUp>
                <Tooltip title="Sign Out">
                  <IconButton color="inherit" onClick={authSignOut}>
                    <SignOutIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Hidden>
            </ToolbarContainer>
          </Grid>
          {location.pathname.startsWith("/journeys/") &&
            !location.pathname.includes("primes") &&
            !location.pathname.match(/learning-materials\/.+/) &&
            !location.pathname.match(/surveys\/.+/) &&
            !location.pathname.includes("nudges") && (
              <Grid item>
                <Box display="flex" flex={1} justifyContent="center">
                  {user.groups.includes("Admins") ||
                  !user.companies?.includes(
                    "9aa46b2c-5fa6-4f0c-839d-35527dd19386"
                  ) ? (
                    <Tabs
                      centered
                      indicatorColor="primary"
                      value={tab}
                      variant="fullWidth"
                      onChange={handleTabChange}
                    >
                      <Tab wrapped label="General Info" />
                      <Tab wrapped label="Structure" />
                      <Tab wrapped label="Scheduled Slots" />
                      <Tab wrapped label="Tips" />
                      <Tab wrapped label="Nudges" />
                      <Tab wrapped label="Learning Materials" />
                      <Tab wrapped label="Tests/Surveys" />
                    </Tabs>
                  ) : (
                    <Tabs
                      centered
                      indicatorColor="primary"
                      value={tab}
                      variant="fullWidth"
                      onChange={handleTabChange}
                    >
                      <Tab wrapped label="General Info" />
                      <Tab wrapped label="Structure" />
                      <Tab wrapped label="Scheduled Slots" />
                      <Tab wrapped label="Tips" />
                      {/* <Tab wrapped label="Nudges" /> */}
                      {/* <Tab wrapped label="Learning Materials" /> */}
                      <Tab wrapped label="Tests/Surveys" />
                    </Tabs>
                  )}
                </Box>
              </Grid>
            )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
